import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuaApiLibService, AUAClientService} from 'aua-api-lib';
import {OauthLibService} from 'oauth-lib';
import {throwError} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Storage, StorageService} from '../../service/storage.service';

@Component({
    selector: 'msgm-app-oauth-callback',
    templateUrl: './oauth-callback.component.html',
    styleUrls: ['./oauth-callback.component.scss']
})
export class OauthCallbackComponent implements OnInit {

    private auaApiClient: AUAClientService;

    private POST_LOGIN_SUCCESS_ROUTE: string = 'destination/extended-search';
    private POST_LOGIN_FAILURE_ROUTE: string = 'destination/extended-search';
    private AUTH_STORAGE_KEY: string = environment.storageKeys.AUTHORIZATION;

    constructor(private oauthLibService: OauthLibService, private router: Router, private auaApiLibService: AuaApiLibService, private storageService: StorageService) {
        this.auaApiClient = auaApiLibService.getAuAClientApi();
    }

    ngOnInit() {

        const completeAuthentication$ = this.oauthLibService.completeAuthentication();

        completeAuthentication$.pipe(
            map((authUser) => {

                    if (!authUser) {
                        throwError('AuthCallbackComponent: User is empty');
                        return;
                    }

                    return authUser;
                }
            )
        ).subscribe(authUser => {

            const authUserRoles: string[] = authUser.profile.roles;
            const canLogin: boolean = this.oauthLibService.canLoginWithRoles(authUserRoles);

            if (!canLogin) {
                throwError('AuthCallbackComponent: Login not permitted with userRoles');
            }

            // Store authorization key
            this.storageService.storeItem(Storage.SESSION, this.AUTH_STORAGE_KEY, authUser.id_token);

            // Update API config with token
            this.auaApiClient.configuration.apiKeys = {
                Authorization: authUser.id_token
            };

            this._redirectTo(this.POST_LOGIN_SUCCESS_ROUTE);

        }, error => {

            this._redirectTo(this.POST_LOGIN_FAILURE_ROUTE);

        });
    }

    /**
     *
     * @param route
     */
    private _redirectTo(route: string) {
        this.router.navigate([route]);
    }

}
