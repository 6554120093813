import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {
    DestinationMemberDTO1
} from 'msgm-api-lib';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {UserGetRequest} from '../../../../../../aua-api-lib/src/lib/aua_angular-client';
import {DestinationDetailsService} from '../destination-details.service';

@Component({
    selector: 'msgm-app-destination-details-members-table',
    templateUrl: './destination-details-members-table.component.html',
    styleUrls: ['./destination-details-members-table.component.scss']
})
export class DestinationDetailsMembersTableComponent implements OnInit, AfterViewInit {

    constructor(private destinationDetailsService: DestinationDetailsService) {
    }
    @Output() userInfoShowClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() set destinationMemberCollection(destinationMemberCollection: DestinationMemberDTO1[]) {
        this._destinationMemberCollection$ = new Observable<DestinationMemberDTO1[]>(observer =>
          observer.next(destinationMemberCollection));
    }

    displayedColumns: string[] = ['user_ia_subject', 'join_state', 'created_at', 'updated_at'];

    private _destinationMemberCollection$: Observable<DestinationMemberDTO1[]>;
    private destinationMemberDtos = [];
    private dataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;

    ngOnInit() {
     this._destinationMemberCollection$.subscribe((r) => {
        this.destinationMemberDtos = r;
      });
     this.dataSource = new MatTableDataSource<any>(this.destinationMemberDtos);
    }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onClickShowUser(iaSubject: any) {
    const regexp = new RegExp('[\\d\\w]*-([\\d\\w]*-)*[\\d\\w]*');
    const isMatched = regexp.test(iaSubject);
    if (isMatched) {
      const userGetRequest: UserGetRequest = {
        subject: iaSubject
      };

      this.destinationDetailsService.getIdentities(userGetRequest).subscribe((response) => {
        const userDetailResponse = response.user_get_identity_detail_response;
        this.userInfoShowClick.emit(userDetailResponse);
      });
    }
  }
}
