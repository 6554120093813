import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserDetailsService} from '../user-details.service';

@Component({
  selector: 'msgm-app-user-detail-navigation-button',
  templateUrl: './user-detail-navigation-button.component.html',
  styleUrls: ['./user-detail-navigation-button.component.scss']
})
export class UserDetailNavigationButtonComponent implements OnInit {

  constructor(private userService: UserDetailsService, private router: Router) {
  }

  ngOnInit() {
  }

  navigateTo($event) {
    this.router.navigate([$event, this.userService.subject]);
  }

}
