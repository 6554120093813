import {Component, Input, OnInit} from '@angular/core';
import {UserDTO} from 'aua-api-lib';
import {Observable} from 'rxjs';

@Component({
  selector: 'msgm-app-user-login-information',
  templateUrl: './user-login-information.component.html',
  styleUrls: ['./user-login-information.component.scss']
})
export class UserLoginInformationComponent implements OnInit {

  private _userDTO$: Observable<UserDTO>;

  constructor() { }

  ngOnInit() {
  }

  @Input() set userDTO(userDTO: UserDTO) {
    this._userDTO$ = new Observable<UserDTO>(observer => observer.next(userDTO));
  }

}
