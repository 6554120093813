/**
 * ioneaccess Message Broker Management (MSGM) API
 * API for ioneaccess Message Broker Management (MSGM)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TimeStatsDTO1 } from './timeStatsDTO1';


/**
 * A message sent to a user.
 */
export interface MessageDeliveryDTO1 { 
    /**
     * How many times this message is supposed to be delivered without being acknowledged.
     */
    delivery_attempts: number;
    /**
     * Whether the message was properly processed or not:   - OK: properly processed   - WARNING: properly processed but something was incorrect/unexpected   - ERROR: could not be processed at all (e.g. exception) 
     */
    delivery_state?: MessageDeliveryDTO1.DeliveryStateEnum;
    /**
     * The reason behind an unsuccessful processing. Should NOT be set when 'delivery_state' is 'OK'.
     */
    delivery_reason?: string;
    /**
     * When this message was acknowledged.
     */
    consumed_at?: Date;
    time_stats: TimeStatsDTO1;
    /**
     * Whether this delivery was already consumed or not.
     */
    was_consumed: boolean;
}
export namespace MessageDeliveryDTO1 {
    export type DeliveryStateEnum = 'OK' | 'WARNING' | 'ERROR';
    export const DeliveryStateEnum = {
        OK: 'OK' as DeliveryStateEnum,
        WARNING: 'WARNING' as DeliveryStateEnum,
        ERROR: 'ERROR' as DeliveryStateEnum
    };
}
