import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Storage, StorageService} from '../service/storage.service';

@Injectable()
export class HTTPGlobalResponse implements HttpInterceptor {
    constructor(private router: Router, private storageService: StorageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event) => {

                    if (event instanceof HttpResponse) {
                        // Handle Global Response
                        console.log('intercepted: ', event);
                    }

                },
                (error) => {

                    console.log('intercepted error: ', error);

                    // 401 is used for expired tokens
                    if (error.status === 401) {
                        if (error.error.indexOf('token is expired')) {
                            this.storageService.storeItem(Storage.SESSION, 'logoutReason', 'Token is expired');
                            this.router.navigate(['/login']);
                        }
                    }
                }
            )
        );
    }
}
