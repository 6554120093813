import {ModuleWithProviders, NgModule} from '@angular/core';
import {DefaultMsgmConfiguration, MsgmConfiguration, MsgmConfigurationProvider} from './msgm-api-lib-configurations';
import {MsgmApiLibComponent} from './msgm-api-lib.component';
import {ApiModule, Configuration, ConfigurationParameters} from './msgm_angular-client';

export function MsgmConfigFactory(): Configuration {
    const params: ConfigurationParameters = {};
    return new Configuration(params);
}

@NgModule({
    declarations: [MsgmApiLibComponent],
    imports: [
        ApiModule.forRoot(MsgmConfigFactory)
    ],
    exports: [MsgmApiLibComponent]
})
export class MsgmApiLibModule {
    static forRoot(
        msgmConfiguration: MsgmConfiguration = {}
    ): ModuleWithProviders {
        return {
            ngModule: MsgmApiLibModule,
            providers: [
                msgmConfiguration.config || {
                    provide: MsgmConfigurationProvider,
                    useClass: DefaultMsgmConfiguration
                }
            ]
        };
    }
}
