import {Injectable} from '@angular/core';
import {
    CollectionFilterDTO1,
    DestinationDiscoverRequest1,
    DestinationDiscoverResponse1,
    DestinationListDTO1,
    DestinationListRequest1,
    DestinationListResponse1,
    MsgmApiLibService,
    MSGMClient1Service
} from 'msgm-api-lib';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExtendedSearchService {

    private msgmApiClient: MSGMClient1Service;

    constructor(private msgmLibService: MsgmApiLibService) {
        this.msgmApiClient = msgmLibService.getMsgmClientApi();
    }

    public discoverDestination(destinationDiscoverRequest: DestinationDiscoverRequest1): Observable<DestinationDiscoverResponse1> {
        return this.msgmApiClient.discoverDestination('', '', '', destinationDiscoverRequest);
    }

    public listDestinations(): Observable<DestinationListResponse1> {

        const collectionFilter: CollectionFilterDTO1 = {
            creation_interval: {}
        };

        const destinationListDTO: DestinationListDTO1 = {
            collection_filter: collectionFilter
        };

        const destinationListRequest: DestinationListRequest1 = {
            destination_list_dtos: [destinationListDTO]
        };

        return this.msgmApiClient.listDestinations('', '', '', destinationListRequest);
    }
}
