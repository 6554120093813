import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageCollectionDTO1, MessageDeliveryCollectionDTO1} from 'msgm-api-lib';
import {UserDetailsService} from '../user-details.service';
import {element} from 'protractor';

@Component({
    selector: 'msgm-app-user-details-message-table',
    templateUrl: './user-details-message-table.component.html',
    styleUrls: ['./user-details-message-table.component.scss']
})
export class UserDetailsMessageTableComponent implements OnInit {
    @Input() messageDeliveryCollectionDTO: MessageDeliveryCollectionDTO1;
    @Input() messageCollectionDTO: MessageCollectionDTO1;
    @Output() messageDetailsClick: EventEmitter<any> = new EventEmitter<any>();

    displayedColumns: string[] = ['message_uid', 'created_at', 'details', 'delete'];

    private _collection = [];
    private _count: number = 0;

    constructor(private userDetailsService: UserDetailsService) {
    }

    ngOnInit() {
        this._findCollectionAndCountToDisplay();
    }

    private _findCollectionAndCountToDisplay() {

        if (typeof this.messageDeliveryCollectionDTO !== 'undefined') {
            this._count = this.messageDeliveryCollectionDTO.total_count;
            this._collection = this.messageDeliveryCollectionDTO.collection;
            return;
        }

        if (typeof this.messageCollectionDTO !== 'undefined') {
            this._count = this.messageCollectionDTO.total_count;
            this._collection = this.messageCollectionDTO.collection;
            return;
        }
    }

    onMessageDetailsClick(element: any) {
        this.messageDetailsClick.emit(element);
    }
    onMessageDeleteClick(element: any) {
      const toDelete = confirm('Do you want to delete this message?');

      if (toDelete) {
        this.userDetailsService.deleteMessage(element.uid);

        // tslint:disable-next-line:no-shadowed-variable
        const trElement = this._getCorrectDomTrElement(element.uid);
        trElement.parentElement.removeChild(trElement);
      }
    }

    private _getCorrectDomTrElement(uid: string) {
      const tdElements = document.getElementsByClassName('cdk-column-message_uid ');

      // @ts-ignore
      for (const tdElement of tdElements) {
        if (tdElement.textContent.includes(uid)) {
          return tdElement.parentElement;
        }
      }
    }

}
