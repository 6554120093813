/**
 * ioneaccess Message Broker Management (MSGM) API
 * API for ioneaccess Message Broker Management (MSGM)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Response object which contains info about a destination.
 */
export interface DestinationDiscoverDetailDTO1 { 
    /**
     * DTO identifier to correlate.
     */
    dto_id?: string;
    /**
     * Whether the destination has been discovered (true) or not (false).
     */
    discovered: boolean;
    /**
     * Destination identifier.
     */
    uid: string;
    /**
     * Destination name.
     */
    dest_name: string;
    /**
     * Destination title.
     */
    title: string;
    /**
     * The IA subject of the destination owner.
     */
    owner_ia_subject: string;
    /**
     * Destination join policy.
     */
    join_policy: DestinationDiscoverDetailDTO1.JoinPolicyEnum;
    /**
     * Destination type.
     */
    dest_type: DestinationDiscoverDetailDTO1.DestTypeEnum;
    /**
     * Destination domain.
     */
    domain: string;
    /**
     * Destination alias.
     */
    alias: string;
    /**
     * Defines how many seconds a user (including the owner) has to wait between 2 receive requests.
     */
    receive_interval: number;
    /**
     * Defines how many seconds a user (including the owner) has to wait between 2 send requests.
     */
    send_interval: number;
}
export namespace DestinationDiscoverDetailDTO1 {
    export type JoinPolicyEnum = '' | 'PUBLIC' | 'PRIVATE' | 'PROPERTY';
    export const JoinPolicyEnum = {
        Empty: '' as JoinPolicyEnum,
        PUBLIC: 'PUBLIC' as JoinPolicyEnum,
        PRIVATE: 'PRIVATE' as JoinPolicyEnum,
        PROPERTY: 'PROPERTY' as JoinPolicyEnum
    };
    export type DestTypeEnum = '' | 'QUEUE' | 'TOPIC';
    export const DestTypeEnum = {
        Empty: '' as DestTypeEnum,
        QUEUE: 'QUEUE' as DestTypeEnum,
        TOPIC: 'TOPIC' as DestTypeEnum
    };
}
