import {ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import {QuicksearchLibModule} from 'quicksearch-lib';
import {AuaApiLibModule, AuAConfigurationProvider, AuALibConfiguration} from 'aua-api-lib';
import {MsgmConfigurationProvider, MsgmLibConfiguration} from 'msgm-api-lib';
import {MsgmApiLibModule} from '../../../msgm-api-lib/src/lib/msgm-api-lib.module';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {VendorLibModule} from 'vendor-lib';
import {OauthLibModule, OauthConfigurationProvider, OauthLibConfiguration} from 'oauth-lib';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {DestinationDetailsTableComponent} from './component/destination-details/destination-details-table/destination-details-table.component';
import {MsgmDashboardLoginComponent} from './component/msgm-dashboard-login/msgm-dashboard-login.component';
import {OauthCallbackComponent} from './component/oauth-callback/oauth-callback.component';
import {SiteLayoutComponent} from './component/_layout/site-layout/site-layout.component';
import {HeaderComponent} from './component/header/header.component';
import {ExtendedSearchPageComponent} from './component/extended-search/extended-search-page/extended-search-page.component';
import {ExtendedSearchFormComponent} from './component/extended-search/extended-search-form/extended-search-form.component';
import {ExtendedSearchResultsComponent} from './component/extended-search/extended-search-results/extended-search-results.component';
import {DestinationDetailsPageComponent} from './component/destination-details/destination-details-page/destination-details-page.component';
import {DestinationDetailsMembersTableComponent} from './component/destination-details/destination-details-members-table/destination-details-members-table.component';
import {DestinationDetailsSendMessageFormComponent} from './component/destination-details/destination-details-send-message-form/destination-details-send-message-form.component';
import {UserDetailMessageIncommingTableComponent} from './component/user-details/user-detail-message-incomming-table/user-detail-message-incomming-table.component';
import {UserDetailsPageComponent} from './component/user-details/user-details-page/user-details-page.component';
import {UserDetailsMessageTableComponent} from './component/user-details/user-details-message-table/user-details-message-table.component';
import {UserDetailsMessageDetailsPopupComponent} from './component/user-details/user-details-message-details-popup/user-details-message-details-popup.component';
import {HTTPGlobalResponse} from './interceptor/http-global-response.interceptor';
import { UserLoginInformationComponent } from './component/header/user-login-information/user-login-information.component';
import { WithLoadingPipe } from './pipe/with-loading.pipe';
import { MsgmDetailsPageComponent } from './component/msgm-details/msgm-details-page/msgm-details-page.component';
import { MsgmDetailsSearchPageComponent } from './component/msgm-details/msgm-details-search-page/msgm-details-search-page.component';
import { UserDetailIncommingDeliveriesComponent } from './component/user-details/user-detail-incomming-deliveries/user-detail-incomming-deliveries.component';
import { UserDetailNavigationButtonComponent } from './component/user-details/user-detail-navigation-button/user-detail-navigation-button.component';
import { UserDetailOutgoingMessagesComponent } from './component/user-details/user-detail-outgoing-messages/user-detail-outgoing-messages.component';
import { UserDetailMessageComponent } from './component/user-details/user-detail-message/user-detail-message.component';
import { UserDetailDestinationsComponent } from './component/user-details/user-detail-destinations/user-detail-destinations.component';
import { UserDetailMessageFilterComponent } from './component/user-details/user-detail-message-filter/user-detail-message-filter.component';
import { UserDetailMessagePayloadPopupComponent } from './component/user-details/user-detail-message-payload-popup/user-detail-message-payload-popup.component';
import {MatDatepickerModule, MatPaginatorModule} from '@angular/material';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { DestinationCreatePageComponent } from './component/destination-details/destination-create-page/destination-create-page.component';
import { UserInfoPopupComponent } from './component/destination-details/user-info-popup/user-info-popup.component';
import { BreadcrumbLayoutComponent } from './component/_layout/breadcrumb-layout/breadcrumb-layout.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { DestinationPageComponent } from './component/destination-details/destination-page/destination-page.component';
import { UserDetailMessageOutgoingTableComponent } from './component/user-details/user-detail-message-outgoing-table/user-detail-message-outgoing-table.component';

/**
 * Settings
 */

// Todo: Where to store interfaces
export interface OicdSettings {
    authority: string;
    client_id: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    automaticSilentRenew?: boolean;
    filterProtocolClaims?: boolean;
    accessTokenExpiringNotificationTime?: number;
    loadUserInfo?: boolean;
}

export interface Settings {
    basePathAuA: string;
    basePathMsgn: string;
    baseURL: string;
    loginWithRoles: string[];
    oicdClientSettings: OicdSettings;
}

/**
 * CONFIGURATION STORAGE
 */

@Injectable({providedIn: 'root'})
export class ConfigurationStore {

    private _oauthLibConfig: OauthLibConfiguration;
    private _auaConfig: AuALibConfiguration;
    private _msgmConfig: MsgmLibConfiguration;

    setOAuthConfig(config: OauthLibConfiguration) {
        this._oauthLibConfig = config;
    }

    getOAuthConfig() {
        return this._oauthLibConfig;
    }

    setAuAConfig(config: AuALibConfiguration) {
        this._auaConfig = config;
    }

    getAuAConfig() {
        return this._auaConfig;
    }

    setMsgmConfig(config: MsgmLibConfiguration) {
        this._msgmConfig = config;
    }

    getMsgmConfig() {
        return this._msgmConfig;
    }
}

/**
 * PROVIDER
 */

@Injectable({providedIn: 'root'})
export class OauthConfigMsgMDashboard implements OauthConfigurationProvider {

    constructor(private configStore: ConfigurationStore) {
    }

    get config(): OauthLibConfiguration {
        return this.configStore.getOAuthConfig();
    }
}

@Injectable({providedIn: 'root'})
export class AuAConfigMsgMDashboard implements AuAConfigurationProvider {

    constructor(private configStore: ConfigurationStore) {
    }

    get config(): AuALibConfiguration {
        return this.configStore.getAuAConfig();
    }
}

@Injectable({providedIn: 'root'})
export class MsgmConfigMsgMDashboard implements MsgmConfigurationProvider {

    constructor(private configStore: ConfigurationStore) {
    }

    get config(): MsgmLibConfiguration {
        return this.configStore.getMsgmConfig();
    }
}


/**
 * Loading app settings before starting app
 *
 * @param configurationStore
 * @param http
 */
export function initApp(configurationStore: ConfigurationStore, http: HttpClient) {
    return () => {
        return http.get('assets/settings/settings.json').toPromise().then(
            (settings: Settings) => {

                const oicdClientConfiguration: OauthLibConfiguration = {
                    oicdClient: settings.oicdClientSettings,
                    loginWithRoles: settings.loginWithRoles
                };

                const auaConfiguration: AuALibConfiguration = {
                    apiConfig: {
                        basePathAuA: settings.basePathAuA,
                        authorizationSessionStorageKey: environment.storageKeys.AUTHORIZATION
                    }
                };

                const msgmConfiguration: MsgmLibConfiguration = {
                    apiConfig: {
                        basePathMsgm: settings.basePathMsgn,
                        authorizationSessionStorageKey: environment.storageKeys.AUTHORIZATION
                    }
                };

                configurationStore.setOAuthConfig(oicdClientConfiguration);
                configurationStore.setAuAConfig(auaConfiguration);
                configurationStore.setMsgmConfig(msgmConfiguration);
            });
    };
}


@NgModule({
  declarations: [
    AppComponent,
    MsgmDashboardLoginComponent,
    OauthCallbackComponent,
    SiteLayoutComponent,
    HeaderComponent,
    ExtendedSearchPageComponent,
    ExtendedSearchFormComponent,
    ExtendedSearchResultsComponent,
    DestinationDetailsPageComponent,
    DestinationDetailsMembersTableComponent,
    DestinationDetailsSendMessageFormComponent,
    DestinationDetailsTableComponent,
    UserDetailsPageComponent,
    UserDetailsMessageTableComponent,
    UserDetailsMessageDetailsPopupComponent,
    UserLoginInformationComponent,
    WithLoadingPipe,
    MsgmDetailsPageComponent,
    MsgmDetailsSearchPageComponent,
    UserDetailIncommingDeliveriesComponent,
    UserDetailNavigationButtonComponent,
    UserDetailOutgoingMessagesComponent,
    UserDetailMessageComponent,
    UserDetailDestinationsComponent,
    UserDetailMessageFilterComponent,
    UserDetailMessagePayloadPopupComponent,
    DestinationCreatePageComponent,
    UserDetailMessageIncommingTableComponent,
    UserDetailMessageOutgoingTableComponent,
    UserInfoPopupComponent,
    BreadcrumbLayoutComponent,
    DestinationPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VendorLibModule,
    QuicksearchLibModule,
    HttpClientModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    BreadcrumbModule,
    AuaApiLibModule.forRoot({
      config: {
        provide: AuAConfigurationProvider,
        useClass: AuAConfigMsgMDashboard
      }
    }),
    MsgmApiLibModule.forRoot({
      config: {
        provide: MsgmConfigurationProvider,
        useClass: MsgmConfigMsgMDashboard
      }
    }),
    OauthLibModule.forRoot(
      {
        config: {
          provide: OauthConfigurationProvider,
          useClass: OauthConfigMsgMDashboard
        }
      }
    ),
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatPaginatorModule
  ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [ConfigurationStore, HttpClient]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPGlobalResponse,
            multi: true
        }
    ],
    entryComponents: [UserDetailsMessageDetailsPopupComponent, UserDetailMessagePayloadPopupComponent, UserInfoPopupComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
}
