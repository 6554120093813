import {Injectable} from '@angular/core';


export enum Storage {
    SESSION = 1,
    LOCAL = 2
}

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    storeItem(storage: Storage, key: string, value: string) {
        const _storage = this._getStorage(storage);
        _storage.setItem(key, value);
    }

    getItem(storage: Storage, key: string) {
        const _storage = this._getStorage(storage);
        const item = _storage.getItem(key);
        return item;
    }

    deleteItem(storage: Storage, key: string) {
        const _storage = this._getStorage(storage);
        _storage.removeItem(key);
    }

    private _getStorage(storage: Storage) {

        switch (storage) {
            case Storage.LOCAL:
                return localStorage;
            case Storage.SESSION:
                return sessionStorage;
        }
    }

}
