import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MessageDeliveryDTO1, MessageDTO1} from 'msgm-api-lib';

@Component({
    selector: 'msgm-app-user-details-message-details-popup',
    templateUrl: './user-details-message-details-popup.component.html',
    styleUrls: ['./user-details-message-details-popup.component.scss']
})
export class UserDetailsMessageDetailsPopupComponent implements OnInit {

    private _messageDTO: MessageDTO1;
    private _messageDeliveryDTO: MessageDeliveryDTO1;
    private _properties : string = ' ';

    constructor(
        public dialogRef: MatDialogRef<UserDetailsMessageDetailsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        console.log('data is: ', this.data);
        this._initMessageObjects();
    }

    private _initMessageObjects() {

        // has message property -> must be of type incomming delivery
        if (this.data.message !== undefined) {
            this._messageDTO = this.data.message;
            this._messageDeliveryDTO = this.data.delivery;
            this._convertPropertyJsonToArray();
            return;
        }

        // data itself is message -> must be of type message
        this._messageDTO = this.data;
    }

    private _convertPropertyJsonToArray() {
      const jsonContent = this._messageDTO.dto.properties;
      if (jsonContent != null) {
          this._properties = 'Course ID: ' + jsonContent.courseId + '\nCourse Reference: '
            + jsonContent.courseReference ;
          return;
      }
    }

    get messageDTO(): MessageDTO1 {
        return this._messageDTO;
    }

    get messageDeliveryDTO(): MessageDeliveryDTO1 {
        return this._messageDeliveryDTO;
    }
}
