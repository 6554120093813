import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
    DestinationDiscoverDTO1,
    DestinationDiscoverRequest1,
    GlobalResultResponse12,
    DestinationDiscoverDetail1,
    DestinationDiscoverDetailDTO1
} from 'msgm-api-lib';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ExtendedSearchService} from '../extended-search.service';
import {ExtendedSearchType} from '../extended-search.shared';
import {Router} from '@angular/router';

@Component({
    selector: 'msgm-app-extended-search-page',
    templateUrl: './extended-search-page.component.html',
    styleUrls: ['./extended-search-page.component.scss']
})
export class ExtendedSearchPageComponent implements OnInit {

    private _destinationDiscoverDetailDTOs: DestinationDiscoverDetailDTO1[];
    private _isLoading = false;

    destinationDiscoverDetailDTOs$: Observable<DestinationDiscoverDetailDTO1[]>;

    constructor(private extendedSearchService: ExtendedSearchService, private router: Router) {
    }

    ngOnInit() {
        //TODO: only for testing purposes
        this.extendedSearchService.listDestinations().subscribe(result => console.log('result', result));
    }

    onExtendSearchFormSubmit($event) {

        const destinationDiscoverRequest: DestinationDiscoverRequest1 = this._buildListDestinationDiscoverRequestFromFormGroup($event);

        this.destinationDiscoverDetailDTOs$ = this.extendedSearchService.discoverDestination(destinationDiscoverRequest).pipe(
            map((response) => {
                const globalResponse: GlobalResultResponse12 = response.global_response;

                if (globalResponse.result_state === 'ERROR') {
                    throw new Error(globalResponse.result_message);
                }

                const destinationDiscoverDetails: DestinationDiscoverDetail1[] = response.destination_discover_details;
                return this._getDestinationDiscoverDetailDTOs(destinationDiscoverDetails);

            }));

    }

    private _buildListDestinationDiscoverRequestFromFormGroup(formGroup: FormGroup): DestinationDiscoverRequest1 {

        const discoverSearchType = this._getFormValue('search_type', formGroup);
        let destinationDiscoverRequest: DestinationDiscoverRequest1;

        switch (discoverSearchType) {
            case ExtendedSearchType.UID:
                destinationDiscoverRequest = this._createUIDDestinationDiscoverRequest(formGroup);
                break;
            case ExtendedSearchType.DOMAIN_ALIAS:
                destinationDiscoverRequest = this._createDOMAINALIASDestinationDiscoverRequest(formGroup);
                break;
            case ExtendedSearchType.OWNER_NAME:
                destinationDiscoverRequest = this._createOWNERNAMEDestinationDiscoverRequest(formGroup);
                break;
        }

        return destinationDiscoverRequest;
    }


    private _getFormValue(key: string, formGroup: FormGroup): any {
        return formGroup.get(key).value;
    }

    private _createUIDDestinationDiscoverRequest(formGroup: FormGroup): DestinationDiscoverRequest1 {
        const destinationDiscoverDTO: DestinationDiscoverDTO1 = {
            uid: {
                destination_uid: this._getFormValue('uid', formGroup)
            }
        };

        return {destination_discover_dtos: [destinationDiscoverDTO]};
    }

    private _createDOMAINALIASDestinationDiscoverRequest(formGroup: FormGroup): DestinationDiscoverRequest1 {
        const destinationDiscoverDTO: DestinationDiscoverDTO1 = {
            domain_and_alias: {
                destination_domain: this._getFormValue('destination_domain', formGroup),
                destination_alias: this._getFormValue('destination_alias', formGroup)
            }
        };

        return {destination_discover_dtos: [destinationDiscoverDTO]};
    }

    private _createOWNERNAMEDestinationDiscoverRequest(formGroup: FormGroup): DestinationDiscoverRequest1 {
        const destinationDiscoverDTO: DestinationDiscoverDTO1 = {
            owner_and_name: {
                destination_name: this._getFormValue('destination_name', formGroup),
                owner_identity: {
                    ia_subject: this._getFormValue('ia_subject', formGroup),
                    ext_subject: this._getFormValue('external_id', formGroup),
                    idp_identifier: this._getFormValue('idp_identifier', formGroup)
                }
            }
        };

        return {destination_discover_dtos: [destinationDiscoverDTO]};
    }

    private _getDestinationDiscoverDetailDTOs(destinationDiscoverDetails: DestinationDiscoverDetail1[]): DestinationDiscoverDetailDTO1[] {
        let successfullyProcessedDestinationDiscoverDTOs: DestinationDiscoverDetailDTO1[] = [];

        for (let i = 0; i < destinationDiscoverDetails.length; i++) {

            const destinationDiscoverDetail: DestinationDiscoverDetail1 = destinationDiscoverDetails[i];
            const detailResultResponse = destinationDiscoverDetail.detail_result_response;

            if (detailResultResponse.result_state === 'WARNING') {
                console.warn('[ExtendedSearch]: Warning while processing destinationDiscoverDetail', detailResultResponse.result_message);
                continue;
            }

            if (detailResultResponse.result_state === 'ERROR') {
                console.error('[ExtendedSearch]: Error while processing destinationDiscoverDetail', detailResultResponse.result_message);
                continue;
            }

            successfullyProcessedDestinationDiscoverDTOs.push(destinationDiscoverDetail.destination_discover_detail_dto);
        }

        return successfullyProcessedDestinationDiscoverDTOs;
    }

    get destinationDiscoverDetailDTOs(): DestinationDiscoverDetailDTO1[] {
        return this._destinationDiscoverDetailDTOs;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }
}
