import { Component, OnInit } from '@angular/core';
import {concatMap, map} from 'rxjs/operators';
import {
  GlobalResultResponse1, MessageDTO1, MessageGetDetail1,
  MessageGetDTO1,
  MessageGetRequest1,
  MessageDeliveryDTO1,
  MessageGetDetailDTO1,
} from 'msgm-api-lib';
import {ActivatedRoute} from '@angular/router';
import {MsgmDetailsService} from '../msgm-details.service';
import {FormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'msgm-app-msgm-details-page',
  templateUrl: './msgm-details-page.component.html',
  styleUrls: ['./msgm-details-page.component.scss']
})
export class MsgmDetailsPageComponent implements OnInit {

  private _messageDto: MessageDTO1;
  private _messageDeliveryDtos: Array<MessageDeliveryDTO1>;
  private _messageUid: string;

  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['delivery_attempts', 'delivery_state', 'delivery_reason', 'consumed_at', 'was_consumed', 'created_at', 'updated_at'];

  constructor(private activatedRoute: ActivatedRoute, private msgmDetailService: MsgmDetailsService) {
  }

  ngOnInit() {
    this._readURLParamsAndFetchMessage();
  }

  private _readURLParamsAndFetchMessage() {
    this.activatedRoute.params.pipe(
      concatMap((params) => {

        const uid = params.uid;
        this._messageUid = uid;

        const messageGetRequest: MessageGetRequest1 = this._getMessageGetRequest(uid);
        return this.msgmDetailService.getMessage(messageGetRequest);
      }),
      map((messageGetResponse) => {
        const globalResponse: GlobalResultResponse1 = messageGetResponse.global_response;

        if (globalResponse.result_state === 'ERROR') {
          throw new Error(globalResponse.result_message);
        }

        // @ts-ignore
        return messageGetResponse.message_get_details;
      })
    ).subscribe((messageGetDetails: MessageGetDetail1[]) => {

     console.log(messageGetDetails);
     messageGetDetails.forEach((key, value) => {
       let messageGetDetailDTO1: MessageGetDetailDTO1;
       messageGetDetailDTO1 = key.message_get_detail_dto;

       this._messageDto = messageGetDetailDTO1.message.message;
       this._messageDeliveryDtos = messageGetDetailDTO1.message.deliveries.collection;

     });


    }, (error) => {
      console.error('[messageDetails]: Error while trying to get message', error);
    });
  }

  private _getMessageGetRequest(uid: string): MessageGetRequest1 {

    const messageGetDTo: MessageGetDTO1 = {
      uid: uid
    }

    return {message_get_dtos: [messageGetDTo]};
  }

}
