import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ExtendedSearchType} from '../extended-search.shared';

@Component({
    selector: 'msgm-app-extended-search-form',
    templateUrl: './extended-search-form.component.html',
    styleUrls: ['./extended-search-form.component.scss']
})
export class ExtendedSearchFormComponent implements OnInit {
    @Output() extendedSearchSubmit: EventEmitter<FormGroup> = new EventEmitter();

    private _extendedSearchForm: FormGroup;
    private _extendedSearchTypes: string[] = Object.keys(ExtendedSearchType);
    private ExtendedSearchType = ExtendedSearchType;

    constructor(private formBuilder: FormBuilder) {
        this._setupForm();
    }

    ngOnInit() {
    }

    private _setupForm() {
        console.log('Extended search types: ', this._extendedSearchTypes);

        // Extend search
        this._extendedSearchForm = this.formBuilder.group(
            {
                destination_domain: [''],
                destination_alias: [''],
                destination_name: [''],
                ia_subject: [''],
                external_id: [''],
                idp_identifier: [''],
                uid: [''],
                search_type: [this._extendedSearchTypes[0]]
            }
        );
    }

    onExtendedSearchSubmit(formGroup: FormGroup) {
        this.extendedSearchSubmit.emit(formGroup);
    }

    get extendedSearchForm(): FormGroup {
        return this._extendedSearchForm;
    }

    getValueForEnum(searchType: any) {
      switch (searchType) {
        case ExtendedSearchType.DOMAIN_ALIAS:
          return 'Domain alias';
        case ExtendedSearchType.OWNER_NAME:
          return 'Owner name';
        case ExtendedSearchType.UID:
          return 'Uid';

      }
    }
}
