/**
 * ioneaccess Message Broker Management (MSGM) API
 * API for ioneaccess Message Broker Management (MSGM)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DestinationCreateRequest1 } from '../model/destinationCreateRequest1';
import { DestinationCreateResponse1 } from '../model/destinationCreateResponse1';
import { DestinationDiscoverRequest1 } from '../model/destinationDiscoverRequest1';
import { DestinationDiscoverResponse1 } from '../model/destinationDiscoverResponse1';
import { DestinationGetRequest1 } from '../model/destinationGetRequest1';
import { DestinationGetResponse1 } from '../model/destinationGetResponse1';
import { DestinationListRequest1 } from '../model/destinationListRequest1';
import { DestinationListResponse1 } from '../model/destinationListResponse1';
import { MessageCreateRequest1 } from '../model/messageCreateRequest1';
import { MessageCreateResponse1 } from '../model/messageCreateResponse1';
import { MessageDeleteRequest1 } from '../model/messageDeleteRequest1';
import { MessageDeleteResponse1 } from '../model/messageDeleteResponse1';
import { MessageEditRequest1 } from '../model/messageEditRequest1';
import { MessageEditResponse1 } from '../model/messageEditResponse1';
import { MessageGetRequest1 } from '../model/messageGetRequest1';
import { MessageGetResponse1 } from '../model/messageGetResponse1';
import { MessageListRequest1 } from '../model/messageListRequest1';
import { MessageListResponse1 } from '../model/messageListResponse1';
import { UserCreateRequest1 } from '../model/userCreateRequest1';
import { UserCreateResponse1 } from '../model/userCreateResponse1';
import { UserGetRequest1 } from '../model/userGetRequest1';
import { UserGetResponse1 } from '../model/userGetResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MSGMClient1Service {

    protected basePath = 'https://igate.beook.work/msgm/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new destination.
     * Creates a new destination which let allowed members to exchange messages.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param DestinationCreateRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationCreateRequest1: DestinationCreateRequest1, observe?: 'body', reportProgress?: boolean): Observable<DestinationCreateResponse1>;
    public createDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationCreateRequest1: DestinationCreateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DestinationCreateResponse1>>;
    public createDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationCreateRequest1: DestinationCreateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DestinationCreateResponse1>>;
    public createDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationCreateRequest1: DestinationCreateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createDestination.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createDestination.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createDestination.');
        }

        if (DestinationCreateRequest1 === null || DestinationCreateRequest1 === undefined) {
            throw new Error('Required parameter DestinationCreateRequest1 was null or undefined when calling createDestination.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DestinationCreateResponse1>(`${this.basePath}/destination/create`,
            DestinationCreateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new user.
     * Creates a new user on the message broker. It returns no error if a user exists already.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param UserCreateRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserCreateRequest1: UserCreateRequest1, observe?: 'body', reportProgress?: boolean): Observable<UserCreateResponse1>;
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserCreateRequest1: UserCreateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCreateResponse1>>;
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserCreateRequest1: UserCreateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCreateResponse1>>;
    public createUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserCreateRequest1: UserCreateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createUser.');
        }

        if (UserCreateRequest1 === null || UserCreateRequest1 === undefined) {
            throw new Error('Required parameter UserCreateRequest1 was null or undefined when calling createUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserCreateResponse1>(`${this.basePath}/user/create`,
            UserCreateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing message.
     * 
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param MessageDeleteRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageDeleteRequest1: MessageDeleteRequest1, observe?: 'body', reportProgress?: boolean): Observable<MessageDeleteResponse1>;
    public deleteMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageDeleteRequest1: MessageDeleteRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageDeleteResponse1>>;
    public deleteMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageDeleteRequest1: MessageDeleteRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageDeleteResponse1>>;
    public deleteMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageDeleteRequest1: MessageDeleteRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deleteMessage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deleteMessage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deleteMessage.');
        }

        if (MessageDeleteRequest1 === null || MessageDeleteRequest1 === undefined) {
            throw new Error('Required parameter MessageDeleteRequest1 was null or undefined when calling deleteMessage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MessageDeleteResponse1>(`${this.basePath}/message/delete`,
            MessageDeleteRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all info about a destination.
     * Retrieves all info about a destination, especially useful when the client needs to know the destination UID.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param DestinationDiscoverRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discoverDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationDiscoverRequest1: DestinationDiscoverRequest1, observe?: 'body', reportProgress?: boolean): Observable<DestinationDiscoverResponse1>;
    public discoverDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationDiscoverRequest1: DestinationDiscoverRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DestinationDiscoverResponse1>>;
    public discoverDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationDiscoverRequest1: DestinationDiscoverRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DestinationDiscoverResponse1>>;
    public discoverDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationDiscoverRequest1: DestinationDiscoverRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling discoverDestination.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling discoverDestination.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling discoverDestination.');
        }

        if (DestinationDiscoverRequest1 === null || DestinationDiscoverRequest1 === undefined) {
            throw new Error('Required parameter DestinationDiscoverRequest1 was null or undefined when calling discoverDestination.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DestinationDiscoverResponse1>(`${this.basePath}/destination/discover`,
            DestinationDiscoverRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit an existing message.
     * 
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param MessageEditRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageEditRequest1: MessageEditRequest1, observe?: 'body', reportProgress?: boolean): Observable<MessageEditResponse1>;
    public editMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageEditRequest1: MessageEditRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageEditResponse1>>;
    public editMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageEditRequest1: MessageEditRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageEditResponse1>>;
    public editMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageEditRequest1: MessageEditRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling editMessage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling editMessage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling editMessage.');
        }

        if (MessageEditRequest1 === null || MessageEditRequest1 === undefined) {
            throw new Error('Required parameter MessageEditRequest1 was null or undefined when calling editMessage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MessageEditResponse1>(`${this.basePath}/message/edit`,
            MessageEditRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detailed info about a destination.
     * Gets detailed info about a destination like general info, owned destinations, incoming/outgoing messages.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param DestinationGetRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationGetRequest1: DestinationGetRequest1, observe?: 'body', reportProgress?: boolean): Observable<DestinationGetResponse1>;
    public getDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationGetRequest1: DestinationGetRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DestinationGetResponse1>>;
    public getDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationGetRequest1: DestinationGetRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DestinationGetResponse1>>;
    public getDestination(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationGetRequest1: DestinationGetRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getDestination.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getDestination.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getDestination.');
        }

        if (DestinationGetRequest1 === null || DestinationGetRequest1 === undefined) {
            throw new Error('Required parameter DestinationGetRequest1 was null or undefined when calling getDestination.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DestinationGetResponse1>(`${this.basePath}/destination/get`,
            DestinationGetRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detailed info about a message.
     * Gets detailed info about a message like general info, message payload, detailed deliveries.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param MessageGetRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageGetRequest1: MessageGetRequest1, observe?: 'body', reportProgress?: boolean): Observable<MessageGetResponse1>;
    public getMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageGetRequest1: MessageGetRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageGetResponse1>>;
    public getMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageGetRequest1: MessageGetRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageGetResponse1>>;
    public getMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageGetRequest1: MessageGetRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getMessage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getMessage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getMessage.');
        }

        if (MessageGetRequest1 === null || MessageGetRequest1 === undefined) {
            throw new Error('Required parameter MessageGetRequest1 was null or undefined when calling getMessage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MessageGetResponse1>(`${this.basePath}/message/get`,
            MessageGetRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detailed info about a user.
     * Gets detailed info about a user like general info, owned destinations, incoming/outgoing messages.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param UserGetRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserGetRequest1: UserGetRequest1, observe?: 'body', reportProgress?: boolean): Observable<UserGetResponse1>;
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserGetRequest1: UserGetRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGetResponse1>>;
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserGetRequest1: UserGetRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGetResponse1>>;
    public getUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserGetRequest1: UserGetRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getUser.');
        }

        if (UserGetRequest1 === null || UserGetRequest1 === undefined) {
            throw new Error('Required parameter UserGetRequest1 was null or undefined when calling getUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserGetResponse1>(`${this.basePath}/user/get`,
            UserGetRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List destinations.
     * Gets a collection of destinations and related metadata.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param DestinationListRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listDestinations(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationListRequest1: DestinationListRequest1, observe?: 'body', reportProgress?: boolean): Observable<DestinationListResponse1>;
    public listDestinations(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationListRequest1: DestinationListRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DestinationListResponse1>>;
    public listDestinations(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationListRequest1: DestinationListRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DestinationListResponse1>>;
    public listDestinations(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DestinationListRequest1: DestinationListRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling listDestinations.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling listDestinations.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling listDestinations.');
        }

        if (DestinationListRequest1 === null || DestinationListRequest1 === undefined) {
            throw new Error('Required parameter DestinationListRequest1 was null or undefined when calling listDestinations.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DestinationListResponse1>(`${this.basePath}/destination/list`,
            DestinationListRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detailed info about a message.
     * Gets detailed info about a message like general info, message payload, detailed deliveries.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param MessageListRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMessages(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageListRequest1: MessageListRequest1, observe?: 'body', reportProgress?: boolean): Observable<MessageListResponse1>;
    public listMessages(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageListRequest1: MessageListRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageListResponse1>>;
    public listMessages(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageListRequest1: MessageListRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageListResponse1>>;
    public listMessages(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageListRequest1: MessageListRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling listMessages.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling listMessages.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling listMessages.');
        }

        if (MessageListRequest1 === null || MessageListRequest1 === undefined) {
            throw new Error('Required parameter MessageListRequest1 was null or undefined when calling listMessages.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MessageListResponse1>(`${this.basePath}/message/list`,
            MessageListRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send a message to a destination.
     * Sends a message to a destination. The sender must be a destination member therefore he must join the destination first.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param MessageCreateRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageCreateRequest1: MessageCreateRequest1, observe?: 'body', reportProgress?: boolean): Observable<MessageCreateResponse1>;
    public sendMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageCreateRequest1: MessageCreateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageCreateResponse1>>;
    public sendMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageCreateRequest1: MessageCreateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageCreateResponse1>>;
    public sendMessage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, MessageCreateRequest1: MessageCreateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling sendMessage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling sendMessage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling sendMessage.');
        }

        if (MessageCreateRequest1 === null || MessageCreateRequest1 === undefined) {
            throw new Error('Required parameter MessageCreateRequest1 was null or undefined when calling sendMessage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MessageCreateResponse1>(`${this.basePath}/message/create`,
            MessageCreateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
