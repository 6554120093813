import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  IdentityDTO1,
  MessageCollectionDTO1,
  MessageDeliveryCollectionDTO1, MessageDeliveryCollectionDTO1Collection,
  MessageDeliveryDTO1,
  MessageDTO1,
  MessageGetRequest1, MessageGetResponse1
} from 'msgm-api-lib';
import {UserDetailsService} from '../user-details.service';
import {UserGetRequest} from '../../../../../../aua-api-lib/src/lib/aua_angular-client';
import {PageEvent} from '@angular/material';

@Component({
  selector: 'msgm-app-user-detail-message',
  templateUrl: './user-detail-message.component.html',
  styleUrls: ['./user-detail-message.component.scss']
})
export class UserDetailMessageComponent implements OnInit {

  // incoming-messages
  @Input() set messageDeliveryCollectionDTO(messageDeliveryCollectionDTO1: MessageDeliveryCollectionDTO1) {
    this._messageDeliveryCollectionDTO = messageDeliveryCollectionDTO1;
  };

  // outgoing-messages
  @Input() set messageCollectionDTO(messageCollectionDTO1: MessageCollectionDTO1) {
    this._messageCollectionDTO = messageCollectionDTO1;
  }

  @Input() offset = 0;
  @Input() pageIndex = 0;
  @Output() payloadShowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextMessageClick: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: string[] = ['created_at', 'attempts', 'consumed_at', 'sender'];

  public properties: any;
  public messageDTO: MessageDTO1;
  public messageDeliveryDTO: MessageDeliveryDTO1;
  public count = 0;
  public pageSizeOptions = [10];

  public showIncomingMessages = false;
  public showOutgoingMessages = false;

  public _messageDeliveryCollectionDTO: MessageDeliveryCollectionDTO1;
  public _messageCollectionDTO: MessageCollectionDTO1;

  private senderInfos = [];
  private showNextMessagesButton = true;
  private selectedRowInfo;


  constructor(private userDetailsService: UserDetailsService) {
  }

  ngOnInit() {
    this.initComponent();
  }

  private initComponent() {
    this._findCollectionTypeInitialise();
    this._setPageOptionForPaginator();
    this._checkIfNextButtonToShow();
  }

  /**
   * @param dtos
   * @private
   */
  private _initiateSenderIdentitiesFromIncoming(dtos: MessageDeliveryCollectionDTO1Collection[]) {
    dtos.forEach((dto: MessageDeliveryCollectionDTO1Collection) => {
      this._getSenderSubjectFromCollection(dto.message.dto.sender_identity);
    });
  }

  /**
   * @param dtos
   * @private
   */
  private _initiateSenderIdentitiesFromOutgoing(dtos: MessageDTO1[]) {
    dtos.forEach((dto: MessageDTO1) => {
      this._getSenderSubjectFromCollection(dto.dto.sender_identity);
    });
  }

  private _getSenderSubjectFromCollection(sender: IdentityDTO1) {
    if (typeof sender.ia_subject !== 'undefined') {
      const iaSubject = sender.ia_subject;
      const senderEmail = this.getSenderIdentity(iaSubject);
      if (!senderEmail) {
        const userGetRequest: UserGetRequest = {
          subject: iaSubject
        };
        this.userDetailsService.getIdentities(userGetRequest).subscribe((response) => {
          const userIdentityDetailResponse = response.user_get_identity_detail_response;
          if (userIdentityDetailResponse) {
            userIdentityDetailResponse.identities.forEach((v, k) => {
              const senderInfo = {
                ia_subject: response.user_get_identity_detail_response.subject,
                email: v.email
              };
              this.senderInfos.push(senderInfo);
            });
          }
        });
      }
    }
  }

  private getSenderIdentity(iaSubject) {
    let senderEmail = null;
    this.senderInfos.forEach((v, k) => {
      if (v.ia_subject === iaSubject.ia_subject) {
        senderEmail = v.email;
      }
    });
    return senderEmail;
  }

  private _findCollectionTypeInitialise() {
    if (typeof this._messageDeliveryCollectionDTO !== 'undefined') {
      // Incoming...
      this.count = this._messageDeliveryCollectionDTO.total_count;
      this.showIncomingMessages = true;
      this.showOutgoingMessages = false;

      if (this._messageDeliveryCollectionDTO.collection.length) {
        this._initiateSenderIdentitiesFromIncoming(this._messageDeliveryCollectionDTO.collection);
        const firstDTO: MessageDeliveryCollectionDTO1Collection = this._messageDeliveryCollectionDTO.collection[0];
        this._getMessageInfoForMessageUid(firstDTO.message.uid);
        this.messageDeliveryDTO = firstDTO.delivery;
      }

    } else if (typeof this._messageCollectionDTO !== 'undefined') {
      // Outgoing...
      this.count = this._messageCollectionDTO.total_count;
      this.showIncomingMessages = false;
      this.showOutgoingMessages = true;

      if (this._messageCollectionDTO.collection.length) {
        this._initiateSenderIdentitiesFromOutgoing(this._messageCollectionDTO.collection);
        const firstDTO: MessageDTO1 = this._messageCollectionDTO.collection[0];
        this._getMessageInfoForMessageUid(firstDTO.uid);
      }
    }
  }

  selectedRow(row: any) {
    this.selectedRowInfo = row;
    if (typeof row.uid !== 'undefined') {
      this._getMessageInfoForMessageUid(row.uid);
    }
    if (typeof row.message !== 'undefined') {
      this._getMessageInfoForMessageUid(row.message.uid);
    }
    if (typeof row.delivery !== 'undefined') {
      this.messageDeliveryDTO = row.delivery;
    }
  }

  _setPageOptionForPaginator() {
    const times = Math.floor(this.count / 10);
    for (let i = 1; i <= times; i++) {
      this.pageSizeOptions.push(i * 10);
    }
  }

  onIncomingRowClick(row: any) {
    this.selectedRow(row);
  }

  onOutgoingRowClick(row: any) {
    this.selectedRow(row);
  }

  onPaginator(pageEvent: PageEvent) {
    this.nextMessageClick.emit(pageEvent);
  }

  _checkIfNextButtonToShow() {
    const times = Math.floor(this.count / 10) * 10;
    if (times === this.offset) {
      this.showNextMessagesButton = false;
    }
  }

  _getMessageInfoForMessageUid(messageUID: string) {
    const messageGetRequest: MessageGetRequest1 = {
      message_get_dtos: [
        {uid: messageUID}
      ]
    };
    this.userDetailsService.getMessage(messageGetRequest).subscribe((response) => {
      response.message_get_details.forEach((v, k) => {
        this.messageDTO = v.message_get_detail_dto.message.message;
        console.log(response);
        if (this.messageDTO) {
          this.properties = this.messageDTO.dto.properties;
        }
      });
    });
  }

  onClickShowPayload(element: any) {
    if (element) {
      this.payloadShowClick.emit(element);
    } else {
      this.payloadShowClick.emit('Payload undefined');
    }
  }

  onClickNextMessage() {
    this._checkIfNextButtonToShow();
    this.offset = this.offset + 10;
    this.nextMessageClick.emit(this.offset);
  }
}
