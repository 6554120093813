import {Component, OnInit} from '@angular/core';
import {Storage, StorageService} from '../../service/storage.service';

@Component({
    selector: 'msgm-app-msgm-dashboard-login',
    templateUrl: './msgm-dashboard-login.component.html',
    styleUrls: ['./msgm-dashboard-login.component.scss']
})
export class MsgmDashboardLoginComponent implements OnInit {

    private _logoutReason: string;

    constructor(private storageService: StorageService) {
    }

    ngOnInit() {
        this._getLogoutReason();
    }

    private _getLogoutReason() {
        const logoutReson = this.storageService.getItem(Storage.SESSION, 'logoutReason');

        if (logoutReson) {
            this._logoutReason = logoutReson;
            this.storageService.deleteItem(Storage.SESSION, 'logoutReason');
        }
    }

}
