/**
 * ioneaccess Message Broker Management (MSGM) API
 * API for ioneaccess Message Broker Management (MSGM)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IdentityDTO1 } from './identityDTO1';


/**
 * Request object to create a new destination.
 */
export interface DestinationCreateDTO1 { 
    /**
     * DTO identifier to correlate.
     */
    dto_id?: string;
    /**
     * The owner of the destination. Must be a registered subject.
     */
    owner_identity: IdentityDTO1;
    /**
     * This works as a namespace for a destination alias. An alias is unique within the same domain.
     */
    domain: string;
    /**
     * An alias is used to discover a destination UID without knowing its owner. If not provided, it will be generated by the broker.
     */
    alias?: string;
    /**
     * Defines what happens when a user wants to join the destination.   PUBLIC = the user is accepted automatically   PRIVATE = the owner must allow/deny every join request   PROPERTY = a join request is automatically allowed/denied according to a property set on the user (not yet implemented) 
     */
    join_policy: DestinationCreateDTO1.JoinPolicyEnum;
    /**
     * The type of the destination.   QUEUE = only the owner can read messages, allowed users can write only   TOPIC = other users can join the destination (according to 'join_policy') and can be able to read, write or read-write messages 
     */
    dest_type: DestinationCreateDTO1.DestTypeEnum;
    /**
     * A title which describes the destination.
     */
    title: string;
    /**
     * A name which can be used to discover a destination. A user cannot own 2 destinations with the same name.
     */
    name: string;
    /**
     * Defines how many seconds a user (including the owner) has to wait between 2 receive requests.
     */
    receive_interval: number;
    /**
     * Defines how many seconds a user (including the owner) has to wait between 2 send requests.
     */
    send_interval: number;
}
export namespace DestinationCreateDTO1 {
    export type JoinPolicyEnum = 'PUBLIC' | 'PRIVATE' | 'PROPERTY';
    export const JoinPolicyEnum = {
        PUBLIC: 'PUBLIC' as JoinPolicyEnum,
        PRIVATE: 'PRIVATE' as JoinPolicyEnum,
        PROPERTY: 'PROPERTY' as JoinPolicyEnum
    };
    export type DestTypeEnum = 'QUEUE' | 'TOPIC';
    export const DestTypeEnum = {
        QUEUE: 'QUEUE' as DestTypeEnum,
        TOPIC: 'TOPIC' as DestTypeEnum
    };
}
