import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {User} from 'oidc-client';
import {concatMap, map} from 'rxjs/operators';
import {
    DestinationGetRequest1,
    DestinationGetDTO1,
    GlobalResultResponse1,
    DetailResultResponse1,
    DestinationGetDetail1,
    DestinationDiscoverDetailDTO1,
    DestinationMemberCollectionDTO1,
    MessageCreateDTO1,
    MessageCreateRequest1,
    IdentityDTO1
} from 'msgm-api-lib';
import {OauthLibService} from 'oauth-lib';
import {DestinationDetailsService} from '../destination-details.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {UserInfoPopupComponent} from '../user-info-popup/user-info-popup.component';

@Component({
    selector: 'msgm-app-destination-details-page',
    templateUrl: './destination-details-page.component.html',
    styleUrls: ['./destination-details-page.component.scss']
})
export class DestinationDetailsPageComponent implements OnInit {

    private _destinationMemberCollection: DestinationMemberCollectionDTO1;
    private _destinationDiscoverDetailDTO: DestinationDiscoverDetailDTO1;
    private _destinationUID: string;
    private _loggedInUserSubject: string;

    constructor(private activatedRoute: ActivatedRoute,
                private destinationDetailsService: DestinationDetailsService,
                private oauthLibService: OauthLibService,
                private snackbar: MatSnackBar,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this._readURLParamsAndFetchDestination();
        this._loadLoggedInUser();
    }

    private _readURLParamsAndFetchDestination() {
        this.activatedRoute.params.pipe(
            concatMap((params) => {

                const uid = params['uid'];
                this._destinationUID = uid;

                const destinationGetRequest: DestinationGetRequest1 = this._getDestinationGetRequest(uid);
                return this.destinationDetailsService.getDestination(destinationGetRequest);
            }),
            map((destinationGetResponse) => {
                const globalResponse: GlobalResultResponse1 = destinationGetResponse.global_response;

                if (globalResponse.result_state === 'ERROR') {
                    throw new Error(globalResponse.result_message);
                }

                return destinationGetResponse.destination_get_details;
            })
        ).subscribe((destinationGetDetails: DestinationGetDetail1[]) => {

            this._destinationMemberCollection = this._getDestinationMemberCollection(destinationGetDetails);
            this._destinationDiscoverDetailDTO = this._getDestinationDiscoverDTO(destinationGetDetails);

            console.log('Processed destination member collection: ', this._destinationMemberCollection);
            console.log('Processed destination discover dto: ', this._destinationDiscoverDetailDTO);

        }, (error) => {
            console.error('[DestinationDetails]: Error while trying to get destination', error);
        });
    }

    private _loadLoggedInUser() {
        this.oauthLibService.getUser$().subscribe((user: User) => {
            this._loggedInUserSubject = user.profile.sub;
            console.log('logged in subject is: ', this._loggedInUserSubject);
        });
    }

    onSendMessageFormSubmit(formGroup) {
        const messageCreateRequest: MessageCreateRequest1 = this._getMessageCreateRequest(formGroup);
        console.log('sending', messageCreateRequest);
        this.destinationDetailsService.sendMessage(messageCreateRequest).subscribe((response) => {
            console.log('response', response);
            const messageCreateDetail = response.message_create_details[0];
            if (messageCreateDetail.detail_result_response.result_state === 'OK') {
              this.snackbar.open('Message was sent successfully', '', {
                duration: 5000,
              });
            } else {
              this.snackbar.open( 'Message was not sent.' + messageCreateDetail.detail_result_response.result_message, '', {
                duration: 5000,
              });
            }
        });
    }

    private _getMessageCreateRequest(formGroup: FormGroup): MessageCreateRequest1 {

        const identiyDTO: IdentityDTO1 = {
            ia_subject: this._getFormValue('sender_subject', formGroup)
        };

        const messageCreateDTO: MessageCreateDTO1 = {
            payload: this._getFormValue('message', formGroup),
            payload_type: 'string',
            sender_identity: identiyDTO,
            max_attempts: this._getFormValue('max_attempts', formGroup),
            delivery_policy: this._getFormValue('delivery_policy', formGroup),
            ttl: this._getFormValue('ttl', formGroup),
            destination_uid: this._destinationUID,
            version: this._getFormValue('version', formGroup),
            properties: {}
        };

        const messageCreateRequest: MessageCreateRequest1 = {
            message_create_dtos: [messageCreateDTO]
        };

        return messageCreateRequest;
    }

    private _getFormValue(key: string, formGroup: FormGroup): any {
        return formGroup.get(key).value;
    }

    private _getDestinationGetRequest(uid: string): DestinationGetRequest1 {

        const destinationGetDTO: DestinationGetDTO1 = {
            uid: uid
        };

        return {destination_get_dtos: [destinationGetDTO]};
    }

    private _getDestinationMemberCollection(destinationGetDetails: DestinationGetDetail1[]): DestinationMemberCollectionDTO1 {

        let successfullyProcessedDestinationMemberCollection: DestinationMemberCollectionDTO1;

        for (let i = 0; i < destinationGetDetails.length; i++) {
            const destinationGetDetail: DestinationGetDetail1 = destinationGetDetails[i];
            const detailResultResponse: DetailResultResponse1 = destinationGetDetail.detail_result_response;

            if (detailResultResponse.result_state === 'WARNING') {
                console.warn('[DestinationDetails]: Warning while processing destinationGetDetail', detailResultResponse.result_message);
                continue;
            }

            if (detailResultResponse.result_state === 'ERROR') {
                console.error('[DestinationDetails]: Error while processing destinationGetDetail', detailResultResponse.result_message);
                continue;
            }

            successfullyProcessedDestinationMemberCollection = destinationGetDetail.destination_get_detail_dto.members;
        }

        return successfullyProcessedDestinationMemberCollection;
    }

    private _getDestinationDiscoverDTO(destinationGetDetails: DestinationGetDetail1[]): DestinationDiscoverDetailDTO1 {

        let successfullyProcessedDestinationDetails: DestinationDiscoverDetailDTO1;

        for (let i = 0; i < destinationGetDetails.length; i++) {
            const destinationGetDetail: DestinationGetDetail1 = destinationGetDetails[i];
            const detailResultResponse: DetailResultResponse1 = destinationGetDetail.detail_result_response;

            if (detailResultResponse.result_state === 'WARNING') {
                console.warn('[DestinationDetails]: Warning while processing destinationGetDetail', detailResultResponse.result_message);
                continue;
            }

            if (detailResultResponse.result_state === 'ERROR') {
                console.error('[DestinationDetails]: Error while processing destinationGetDetail', detailResultResponse.result_message);
                continue;
            }

            successfullyProcessedDestinationDetails = destinationGetDetail.destination_get_detail_dto.destination.destination;
        }

        return successfullyProcessedDestinationDetails;
    }

    get destinationDiscoverDetailDTO(): DestinationDiscoverDetailDTO1 {
        return this._destinationDiscoverDetailDTO;
    }

    get destinationMemberCollection(): DestinationMemberCollectionDTO1 {
        return this._destinationMemberCollection;
    }

    get loggedInUserSubject(): string {
        return this._loggedInUserSubject;
    }

    onClickShowUserInfo(userInfo) {
      this.dialog.open(UserInfoPopupComponent, {data: userInfo});
    }
}
