import { Injectable } from '@angular/core';
import {
  MessageGetRequest1,
  MessageListRequest1, MessageListResponse1,
  MsgmApiLibService,
  MSGMClient1Service,
} from 'msgm-api-lib';

import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MsgmDetailsService {
  private _msgmApiClient: MSGMClient1Service;

  constructor(private msgmLibService: MsgmApiLibService) {
    this._msgmApiClient = msgmLibService.getMsgmClientApi();
  }

  // list Mssage from Swager mit parameter definieen
  public listMessages(messageListRequest: MessageListRequest1): Observable<MessageListResponse1> {
    return this._msgmApiClient.listMessages('', '', '', messageListRequest);
  }

  // get Message for message_uid
  public getMessage(messageGetRequest: MessageGetRequest1): Observable<MessageListResponse1> {
    return this._msgmApiClient.getMessage('', '', '', messageGetRequest);
  }
}

