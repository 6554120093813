import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {
    DestinationDiscoverDetailDTO1,
} from 'msgm-api-lib';

@Component({
    selector: 'msgm-app-destination-details-table',
    templateUrl: './destination-details-table.component.html',
    styleUrls: ['./destination-details-table.component.scss']
})
export class DestinationDetailsTableComponent implements OnInit {
    private _destinationDiscoverDetailDTO$: Observable<DestinationDiscoverDetailDTO1[]>;
    private destionationDetail: DestinationDiscoverDetailDTO1;
    constructor() {}

    ngOnInit() {
      this._destinationDiscoverDetailDTO$.subscribe((v) => {
        this.destionationDetail = v[0];
      });
    }

    @Input() set destinationDiscoverDetailDTO(destinationDiscoverDetailDTO: DestinationDiscoverDetailDTO1) {
        this._destinationDiscoverDetailDTO$ = new Observable<DestinationDiscoverDetailDTO1[]>(observer => observer.next([destinationDiscoverDetailDTO]));
    }
}
