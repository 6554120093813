import {Injectable} from '@angular/core';
import {
  MessageDeleteDTO1,
  MessageDeleteRequest1,
  MessageGetRequest1,
  MsgmApiLibService,
  MSGMClient1Service,
  UserGetRequest1,
  UserGetResponse1
} from 'msgm-api-lib';
import {Observable} from 'rxjs';
import {AuaApiLibService, AUAClientService} from 'aua-api-lib';
import {UserGetRequest} from '../../../../../aua-api-lib/src/lib/aua_angular-client';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  private _msgmApiClient: MSGMClient1Service;
  private _auaApiClient: AUAClientService;

  private _subject: string;

  constructor(private msgmLibService: MsgmApiLibService, private auaLibservice: AuaApiLibService) {
    this._msgmApiClient = msgmLibService.getMsgmClientApi();
    this._auaApiClient = auaLibservice.getAuAClientApi();
  }

  public getUserDetails(userGetRequest: UserGetRequest1): Observable<UserGetResponse1> {
    return this._msgmApiClient.getUser('', '', '', userGetRequest);
  }

  public getMessage(messageGetRequest: MessageGetRequest1) {
    return this._msgmApiClient.getMessage('', '', '', messageGetRequest);
  }

  public deleteMessage(uid: string) {
    const messageDeleteDto: MessageDeleteDTO1 = {
      dto_id: '',
      uid
    };

    const requestArray = [];
    requestArray.push(messageDeleteDto);

    const messageDeleteRequest: MessageDeleteRequest1 = {
      message_delete_dtos: requestArray
    };

    this._msgmApiClient.deleteMessage('', '', '', messageDeleteRequest)
      .subscribe((response) => {
        console.log(response);
      });
  }

  public getIdentities(userGetRequest: UserGetRequest) {
    return this._auaApiClient.getIdentitiesForUser('', '', '', userGetRequest);
  }

  public set subject(value: string) {
    this._subject = value;
  }

  public get subject(): string {
    return this._subject;
  }
}
