import {Injectable} from '@angular/core';
import {
  MsgmApiLibService,
  MSGMClient1Service,
  DestinationGetRequest1,
  DestinationGetResponse1,
  MessageCreateRequest1,
  DestinationCreateRequest1, DestinationCreateResponse1
} from 'msgm-api-lib';
import {Observable} from 'rxjs';
import {UserGetRequest} from '../../../../../aua-api-lib/src/lib/aua_angular-client';
import {AuaApiLibService, AUAClientService} from 'aua-api-lib';

@Injectable({
    providedIn: 'root'
})
export class DestinationDetailsService {

    private _msgmApiClient: MSGMClient1Service;
    private _auaApiClient: AUAClientService;


  constructor(private msgmLibService: MsgmApiLibService, private auaLibservice: AuaApiLibService) {
        this._msgmApiClient = msgmLibService.getMsgmClientApi();
        this._auaApiClient = auaLibservice.getAuAClientApi();
    }

    public getDestination(destinationGetRequest: DestinationGetRequest1): Observable<DestinationGetResponse1> {
        return this._msgmApiClient.getDestination('', '', '', destinationGetRequest);
    }

    public sendMessage(createMessageRequest: MessageCreateRequest1) {
        return this._msgmApiClient.sendMessage('', '', '', createMessageRequest);
    }

    public createDestination(destinationCreateRequest: DestinationCreateRequest1): Observable<DestinationCreateResponse1> {
      return this._msgmApiClient.createDestination('', '', '', destinationCreateRequest);
    }

  public getIdentities(userGetRequest: UserGetRequest) {
    return this._auaApiClient.getIdentitiesForUser('', '', '', userGetRequest);
  }
}
