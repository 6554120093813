/**
 * ioneaccess Message Broker Management (MSGM) API
 * API for ioneaccess Message Broker Management (MSGM)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IdentityDTO1 } from './identityDTO1';


/**
 * Request object used to send a message to a destination.
 */
export interface MessageCreateDTO1 { 
    /**
     * DTO identifier to correlate.
     */
    dto_id?: string;
    /**
     * Who sends the message.
     */
    sender_identity: IdentityDTO1;
    /**
     * The destination where this message will be sent to.
     */
    destination_uid: string;
    /**
     * How many times per user this message has to be delivered if the user doesn't acknowledge it.
     */
    max_attempts: number;
    /**
     * Defines whether the message can be read only by current destination members or by new destination members as well. Ignored when destination is not type 'TOPIC'.
     */
    delivery_policy: MessageCreateDTO1.DeliveryPolicyEnum;
    /**
     * Time-to-live in seconds after which the message won't be delivered anymore.
     */
    ttl: number;
    /**
     * Defines the type of the payload. Not relevant for the broker.
     */
    payload_type: string;
    /**
     * Defines the version of the message. Not relevant for the broker.
     */
    version: string;
    /**
     * An object which can contain arbitrary keys and values:   - Key must be a string with a length between 1 and 255 bytes   - Value must be a string with a length between 1 and 63998 bytes This object has no impact on the broker, it will just be forwarded to the receiver. Can be an empty object but the property itself is required. 
     */
    properties: any;
    /**
     * The content of the message. Can be empty but the property itself is required.
     */
    payload: string;
}
export namespace MessageCreateDTO1 {
    export type DeliveryPolicyEnum = 'STATIC' | 'DYNAMIC';
    export const DeliveryPolicyEnum = {
        STATIC: 'STATIC' as DeliveryPolicyEnum,
        DYNAMIC: 'DYNAMIC' as DeliveryPolicyEnum
    };
}
