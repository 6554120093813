import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageCollectionDTO1, MessageDTO1} from 'msgm-api-lib';

@Component({
  selector: 'msgm-app-user-detail-message-outgoing-table',
  templateUrl: './user-detail-message-outgoing-table.component.html',
  styleUrls: ['./user-detail-message-outgoing-table.component.css']
})
export class UserDetailMessageOutgoingTableComponent implements OnInit {
  @Input() messageCollectionDTO: MessageCollectionDTO1;
  @Output() rowClick = new EventEmitter();

  public displayedColumns: string[] = ['created_at', 'max_attempts', 'expires_at'];

  public count: number = 0;

  public collection: MessageDTO1[];
  public selectedRowInfo: MessageDTO1;

  constructor() {
  }

  ngOnInit() {
    this._initCollection();
  }

  private _initCollection() {
    if (typeof this.messageCollectionDTO !== 'undefined') {
      this.count = this.messageCollectionDTO.total_count;
      this.collection = this.messageCollectionDTO.collection;
    }
  }

  public getCreatedAt(dto: MessageDTO1): Date {
    return dto.time_stats.created_at;
  }

  public getMaxAttempts(dto: MessageDTO1): number {
    return dto.dto.max_attempts;
  }

  public getExpiresAt(dto: MessageDTO1) {
    const createAtMS = new Date(dto.time_stats.created_at).getTime();
    const ttlSeconds = (dto.dto.ttl * 1000) + createAtMS;
    return new Date(createAtMS + ttlSeconds);
  }

  public onRowClick(row: any) {
    this.selectedRowInfo = row;
    this.rowClick.emit(row);
  }

}
