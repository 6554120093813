import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  CollectionFilterDTO1, DateTimeIntervalDTO1, DetailResultResponse1, IdentityDTO1,
  MessageDTO1,
  MessageListDTO1,
  MessageListDTO1FilterBy,
  MessageListRequest1,
  MessageListResponse1
} from 'msgm-api-lib';
import {Observable, of} from 'rxjs';
import {MsgmDetailsService} from '../msgm-details.service';
import {concatMap} from 'rxjs/operators';
import {MessageListDetail1, MessageListDetailDTO1} from '../../../../../../msgm-api-lib/src/lib/msgm_angular-client';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'msgm-app-msgm-details-search-page',
  templateUrl: './msgm-details-search-page.component.html',
  styleUrls: ['./msgm-details-search-page.component.scss']
})
export class MsgmDetailsSearchPageComponent implements OnInit {

  private _messageSearchForm: FormGroup;
  private _messageDTOs: Observable<MessageDTO1[]>;
  private _messageListDTOs: MessageListDetailDTO1[];

  displayedColumns: string[] = ['created_at', 'version', 'message_uid', 'payload_type', 'destination_uid', 'detail-button'];

  constructor(private formBuilder: FormBuilder, private msgmDetailsService: MsgmDetailsService, private activatedRoute: ActivatedRoute) {
    this._setupForm();
  }

  ngOnInit() {
  }

  private _setupForm() {
    // Extend search
    this._messageSearchForm = this.formBuilder.group(
      {
        destination_uid: [''],
        version: [''],
        payload_type: [''],
        property_selector: [''],
        payload: [''],
        ia_subject: [''],
        ext_subject: [''],
        idp_identifier: [''],
        from: [''],
        to: [''],
        limit: [0],
        offset: [0]
      }
    );
  }

  onFindMesssage() {
    this._readURLParamsAndFetchMessageDetails();
  }

  private _readURLParamsAndFetchMessageDetails() {
    this.activatedRoute.params.pipe(
      concatMap((params) => {

        const messageListRequest = this._getMessageListRequest();
        return this.msgmDetailsService.listMessages(messageListRequest);

      })).subscribe((messageListResponse: MessageListResponse1) => {
      const messageListDetails: MessageListDetail1[] = messageListResponse.message_list_details;
      // @ts-ignore
      if (messageListDetails) {
        this._messageListDTOs = this._getMessageListDTOs(messageListDetails);
        this._messageDTOs = this._getMessageDTOs();
      }
    });
  }

  private _setIdentityDto(): IdentityDTO1 {
    const subjectValue = this._messageSearchForm.controls['ia_subject'].value;
    const extSubjectValue = this._messageSearchForm.controls['ext_subject'].value;
    const identifierValue = this._messageSearchForm.controls['idp_identifier'].value;

    if (subjectValue && extSubjectValue && identifierValue) {
      const identityDto: IdentityDTO1 = {
        ia_subject: subjectValue,
        ext_subject: extSubjectValue,
        idp_identifier: identifierValue
      };
      return identityDto;
    } else {
      return null;
    }

  }

  private _setMessageListDTOFilterBy(): MessageListDTO1FilterBy {
    const filterBy: MessageListDTO1FilterBy = {
      sender: this._setIdentityDto(),
      destination_uid: this._messageSearchForm.controls['destination_uid'].value,
      version: this._messageSearchForm.controls['version'].value,
      payload_type: this._messageSearchForm.controls['payload_type'].value,
      property_selector: this._messageSearchForm.controls['property_selector'].value,
      payload:  this._messageSearchForm.controls['payload'].value,
    };

    return filterBy;
  }

  private _setCollectionFilterDTO(): CollectionFilterDTO1 {
    const limitValue = this._messageSearchForm.controls['limit'].value;
    const offsetValue = this._messageSearchForm.controls['offset'].value;

    console.log('limitValue: ' + limitValue);
    console.log('offsetVale: ' + offsetValue);
    if (limitValue && offsetValue) {
      const collectionFilter: CollectionFilterDTO1 = {
        creation_interval: this._setDateTimeIntervalDTO(),
        limit: limitValue,
        offset: offsetValue
      };
      return collectionFilter;
    } else {
      return null;
    }
  }

  private _setDateTimeIntervalDTO(): DateTimeIntervalDTO1 {
    const fromValue = this._convertStringToDate(this._messageSearchForm.controls['from'].value);
    const toValue = this._convertStringToDate(this._messageSearchForm.controls['to'].value);

    console.log('From: ' + fromValue);
    console.log('to: ' + toValue);
    if (fromValue && toValue) {
      const dateTimeDto: DateTimeIntervalDTO1 = {
        from: fromValue,
        to: toValue
      };
      return dateTimeDto;
    } else {
      return null;
    }
  }

  private _convertStringToDate(value): any {
    if (value) {
      return new Date(value);
    }
    return;
  }

  private _getMessageListRequest() {
    const messageListDto: MessageListDTO1 = {
      dto_id: '',
      collection_filter: this._setCollectionFilterDTO(),
      filter_by: this._setMessageListDTOFilterBy()
    }
    const messageListDtos: MessageListDTO1[] = [];
    messageListDtos.push(messageListDto);

    const messageListRequest: MessageListRequest1 = {
      message_list_dtos: messageListDtos
    };

    return messageListRequest;
  }

  // @ts-ignore
  private _getMessageListDTOs(messageListDetails: MessageListDetail1[]): MessageListDetailDTO1[] {
    let successfullyProcessedMessageListDTOs: MessageListDetailDTO1[] = [];

    for (let i = 0; i < messageListDetails.length; i++) {
      const messageListDetail: MessageListDetail1 = messageListDetails[i];
      const detailResultResponse: DetailResultResponse1 = messageListDetail.detail_result_response;

      if (detailResultResponse.result_state == 'WARNING') {
        console.warn('[MessageDetails]: Warning while processing userGetDetail', detailResultResponse.result_message);
        continue;
      }

      if (detailResultResponse.result_state == 'ERROR') {
        console.warn('[MessageDetails]: Error while processing userGetDetail', detailResultResponse.result_message);
        continue;
      }

      successfullyProcessedMessageListDTOs.push(messageListDetail.message_list_detail_dto);
    }

    return successfullyProcessedMessageListDTOs;
  }

  private _getMessageDTOs(): Observable<MessageDTO1[]> {
    const successfullyProcessedMessageDTOs: MessageDTO1[] = [];

    for (let i = 0; i < this._messageListDTOs.length; i++) {
      // tslint:disable-next-line:variable-name
      const _messageDTOCollection: MessageDTO1[] = this._messageListDTOs[i].messages.collection;

      for (const messageDto of _messageDTOCollection) {
        successfullyProcessedMessageDTOs.push(messageDto);
      }

    }

    return of(successfullyProcessedMessageDTOs);
  }

}
