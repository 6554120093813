import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DetailResultResponse1, UserGetDetail1, UserGetDetailDTO1, UserGetDTO1, UserGetRequest1} from 'msgm-api-lib';
import {UserDetailsService} from '../user-details.service';
import {MatDialog} from '@angular/material';
import {UserDetailMessagePayloadPopupComponent} from '../user-detail-message-payload-popup/user-detail-message-payload-popup.component';

@Component({
  selector: 'msgm-app-user-detail-incomming-deliveries',
  templateUrl: './user-detail-incomming-deliveries.component.html',
  styleUrls: ['./user-detail-incomming-deliveries.component.scss']
})
export class UserDetailIncommingDeliveriesComponent implements OnInit {
  private userGetDetailDTOs: UserGetDetailDTO1[];
  private limit = 10;
  private offset = 0;
  private pageIndex = 0;

  constructor(private route: ActivatedRoute, private userDetailsService: UserDetailsService, public dialog: MatDialog) {
    this.route.params.subscribe((params) => {
      userDetailsService.subject = params.subject;
      this.initUserDetails();
    });
  }

  ngOnInit() {
    this.initUserDetails();
  }

  private initUserDetails() {
    const request: UserGetRequest1 = this._getUserGetRequest(this.userDetailsService.subject);
    this._fetchUserDetails(request);
  }

  private _getUserGetRequest(subject: string): UserGetRequest1 {
    const userGetDTO: UserGetDTO1 = {
      identity: {
        ia_subject: subject
      },
      incoming_deliveries_filter: {
        limit: this.limit,
        offset: this.offset
      }
    };

    const userGetRequest: UserGetRequest1 = {
      user_get_dtos: [userGetDTO]
    };

    return userGetRequest;
  }

  private _fetchUserDetails(request: UserGetRequest1) {
    this.userDetailsService.getUserDetails(request).subscribe((response) => {
      const userGetDetails: UserGetDetail1[] = response.user_get_details;
      this.userGetDetailDTOs = this._getUserDetailDTOs(userGetDetails);
    });

  }

  private _getUserDetailDTOs(userGetDetails: UserGetDetail1[]): UserGetDetailDTO1[] {
    const successfullyProcessedUserDetailDTOs: UserGetDetailDTO1[] = [];

    for (let i = 0; i < userGetDetails.length; i++) {
      const userGetDetail: UserGetDetail1 = userGetDetails[i];
      const detailResultResponse: DetailResultResponse1 = userGetDetail.detail_result_response;

      if (detailResultResponse.result_state === 'WARNING') {
        console.warn('[UserDetails]: Warning while processing userGetDetail', detailResultResponse.result_message);
        this.offset = 0;
        continue;
      }

      if (detailResultResponse.result_state === 'ERROR') {
        console.warn('[UserDetails]: Error while processing userGetDetail', detailResultResponse.result_message);
        this.offset = 0;
        continue;
      }

      successfullyProcessedUserDetailDTOs.push(userGetDetail.user_create_detail_dto);
    }

    return successfullyProcessedUserDetailDTOs;
  }

  onClickShowPayload(element: any) {
    this.dialog.open(UserDetailMessagePayloadPopupComponent, {data: element});
  }

  /**
   * @param element
   */
  onClickFilterIncommingMessage(element: any) {
    this.offset = 0;

    const userGetDTO: UserGetDTO1 = {
      identity: {
        ia_subject: this.userDetailsService.subject
      },
      incoming_deliveries_filter: {
        creation_interval: element,
        limit: this.limit,
        offset: this.offset
      }
    };

    const userGetRequest: UserGetRequest1 = {
      user_get_dtos: [userGetDTO]
    };

    this._fetchUserDetails(userGetRequest);
  }

  onClickNextMessages(element: any) {
      this.pageIndex = element.pageIndex;
      this.offset = element.pageIndex * 10;
      this.limit = element.pageSize;
      const request: UserGetRequest1 = this._getUserGetRequest(this.userDetailsService.subject);
      this._fetchUserDetails(request);
  }
}
