import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'msgm-app-user-detail-message-payload-popup',
  templateUrl: './user-detail-message-payload-popup.component.html',
  styleUrls: ['./user-detail-message-payload-popup.component.scss']
})
export class UserDetailMessagePayloadPopupComponent implements OnInit {
  private payload = 'Payload ist leer';

  constructor(private clipboardService: ClipboardService, private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<UserDetailMessagePayloadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    console.log('data is: ', this.data);
    this._initMessageObjects();
  }


  private _initMessageObjects() {
    if (this.data !== undefined) {
      this.payload = this.data;
      return;
    }
  }

  onClickCopyPayloadContent() {
    this.clipboardService.copyFromContent(this.payload);
    this.snackbar.open('Payload copied!', '', {
      duration: 2000,
    });
  }
}
