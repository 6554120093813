import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  AuaApiLibService,
  AUAClientService,
  DetailResultResponse,
  UserDetailResponse,
  UserDTO,
  UserGetRequest,
  UserGetResponse,
  UserQuickSearchDetailResponse,
  UserQuickSearchRequest,
  UserQuickSearchResponse,
  UserSmallDTO,
  UserSmallDTOIdentities
} from 'aua-api-lib';
import {OauthLibService} from 'oauth-lib';
import {User} from 'oidc-client';
import {QuickSearchOption} from 'quicksearch-lib';
import {Subject} from 'rxjs';
import {flatMap, switchMap} from 'rxjs/operators';

@Component({
  selector: 'msgm-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  quickSearchOptions: QuickSearchOption[] = [];

  auaClientService: AUAClientService;

  inputSubject$: Subject<string> = new Subject<string>();

  loggedInUserDTO: UserDTO;

  constructor(private auaApiService: AuaApiLibService, private router: Router, private oauthLibService: OauthLibService) {
    this.auaClientService = auaApiService.getAuAClientApi();
  }

  ngOnInit() {
    this._registerQuickSearch();
    this._getLoggedInUser();
  }

  ngOnDestroy() {
    this._unregisterQuickSearch();
  }

  onQuickSearchInputText(value?: string) {
    this.inputSubject$.next(value);
  }

  onQuickSearchResultSelect(quickSearchOption: QuickSearchOption) {
    this.router.navigate(['/user-details/incomming-deliveries', quickSearchOption.value]);
  }

  private _registerQuickSearch() {

    this.inputSubject$.pipe(
      switchMap((value) => {

        const userQuickSearchRequest: UserQuickSearchRequest = {
          search_text: value,
          search_type: 'email_or_subject',
          search_in_identities: false
        };

        return this.auaClientService.quickSearchUser('', '', '', userQuickSearchRequest);
      })
    ).subscribe(
      (response: UserQuickSearchResponse) => {
        const userQuickSearchDetailResponse: UserQuickSearchDetailResponse = response.user_quick_search_detail_response;
        const users: UserSmallDTO[] = userQuickSearchDetailResponse.users;
        this.quickSearchOptions = this._convertUsersToQuickSearchOptions(users);
      }
    );
  }

  private _getLoggedInUser() {
    this.oauthLibService.getUser$().pipe(
      flatMap((user: User) => {
        const userGetRequest: UserGetRequest = {
          subject: user.profile.sub
        };

        return this.auaClientService.getUsers('', '', '', [userGetRequest]);

      })).subscribe((userGetResponse: UserGetResponse) => {
      const userGetDetailResponses: UserDetailResponse[] = userGetResponse.user_get_detail_response;
      this.loggedInUserDTO = this._getLoggedInUserDTO(userGetDetailResponses)[0];
    });
  }

  private _getLoggedInUserDTO(userDTODetailResponses: UserDetailResponse[]) {
    const successfullyProcessedUserDTOs: UserDTO[] = [];

    for (let i = 0; i < userDTODetailResponses.length; i++) {
      const userGetDetailResponse: UserDetailResponse = userDTODetailResponses[i];
      const detailResultResponse: DetailResultResponse = userGetDetailResponse.detail_result_response;

      if (detailResultResponse.result_state === 'WARNING') {
        console.warn('[DestinationDetails]: Warning while processing destinationGetDetail', detailResultResponse.result_message);
        continue;
      }

      if (detailResultResponse.result_state === 'ERROR') {
        console.error('[DestinationDetails]: Error while processing destinationGetDetail', detailResultResponse.result_message);
        continue;
      }

      if (typeof userGetDetailResponse.user !== undefined) {
        successfullyProcessedUserDTOs.push(userGetDetailResponse.user);
      }
    }

    return successfullyProcessedUserDTOs;
  }

  private _unregisterQuickSearch() {
    this.inputSubject$.unsubscribe();
  }

  private _convertUsersToQuickSearchOptions(users: UserSmallDTO[]): QuickSearchOption[] {

    const quickSearchOptions: QuickSearchOption[] = [];

    if (!users) {
      return quickSearchOptions;
    }

    for (let i = 0; i < users.length; i++) {
      const user = users[i];

      quickSearchOptions.push({
          label: user.subject,
          value: user.subject,
          providers: user.identities ? this._getProviders(user.identities) : [''],
          hint: user.email
        }
      );
    }

    return quickSearchOptions;
  }

  /**
   *
   * @param identities
   * @private
   */
  private _getProviders(identities: UserSmallDTOIdentities[]): string[] {
    let providerArray = [];
    for (let i = 0; i < identities.length; i++) {
      providerArray.push(identities[i].provider);
    }
    return providerArray;
  }

}
