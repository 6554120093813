import {Component, Input, OnInit} from '@angular/core';
import {DestinationDiscoverDetailDTO1} from 'msgm-api-lib';
import {Observable} from 'rxjs';

@Component({
    selector: 'msgm-app-extended-search-results',
    templateUrl: './extended-search-results.component.html',
    styleUrls: ['./extended-search-results.component.scss']
})
export class ExtendedSearchResultsComponent implements OnInit {

    displayedColumns: string[] = ['title', 'domain', 'dest_type', 'owner_ia_subject', 'uid'];

    private _destinationDiscoverDTOs$ : Observable<DestinationDiscoverDetailDTO1[]>;

    constructor() {
    }

    ngOnInit() {

    }

    @Input() set destinationDetailDTOs(destinationDiscoverDetailDTOs: DestinationDiscoverDetailDTO1[]) {
        this._destinationDiscoverDTOs$ = new Observable<DestinationDiscoverDetailDTO1[]>(observer => observer.next(destinationDiscoverDetailDTOs));
    }

    get destinationDiscoverDTOs(): Observable<DestinationDiscoverDetailDTO1[]> {
        return this._destinationDiscoverDTOs$;
    }

}

