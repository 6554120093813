import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatRadioChange} from '@angular/material';

@Component({
    selector: 'msgm-app-destination-details-send-message-form',
    templateUrl: './destination-details-send-message-form.component.html',
    styleUrls: ['./destination-details-send-message-form.component.scss']
})
export class DestinationDetailsSendMessageFormComponent implements OnInit {
    @Output() sendMessageSubmit: EventEmitter<FormGroup> = new EventEmitter();

    private _sendMessageForm: FormGroup;
    private _senderDefaultSubject$: Observable<string>;
    private subject: string;

    constructor(private formBuilder: FormBuilder) {

        this._setupForm();
    }

    private _setupForm() {
        // Extend search
        this._sendMessageForm = this.formBuilder.group(
            {
                message: [''],
                max_attempts: [0],
                sender: [''],
                delivery_policy: ['STATIC'],
                ttl: [60],
                version: ['1.0'],
                payload_type: ['XML'],
                sender_subject: [this.senderIASubjectDefault],
            }
        );
    }
    ngOnInit() {}

    onSendMessageSubmit(formGroup: FormGroup) {
        this.sendMessageSubmit.emit(formGroup);
    }

    get sendMessageForm(): FormGroup {
        return this._sendMessageForm;
    }

    @Input() set senderIASubjectDefault(subject: string) {
        //TODO: Improve the set handling.
        this.subject = subject;
        this._sendMessageForm.controls.sender_subject.setValue(subject);
        this._senderDefaultSubject$ = new Observable<string>(observer => observer.next(subject));
    }

  onSenderChange($event: MatRadioChange) {
    console.log($event);
    if ($event.value === 'manual') {
      this._sendMessageForm.controls.sender_subject.setValue('');
    } else if ($event.value === 'default') {
      this._sendMessageForm.controls.sender_subject.setValue(this.subject);
    }
  }
}
