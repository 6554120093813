import {Component, Input, OnInit} from '@angular/core';
import {DestinationCollectionDTO1} from 'msgm-api-lib';

@Component({
  selector: 'msgm-app-user-detail-destinations',
  templateUrl: './user-detail-destinations.component.html',
  styleUrls: ['./user-detail-destinations.component.scss']
})
export class UserDetailDestinationsComponent implements OnInit {
  @Input() private destinationCollections: DestinationCollectionDTO1;

  displayedColumns: string[] = ['is_owner', 'destination_uid', 'name', 'title', 'join_policy', 'domain', 'alias'];

  private collection = [];

  constructor() { }

  ngOnInit() {
    this._findCollectionFromDeliveryToDisplay();
  }

  _findCollectionFromDeliveryToDisplay() {
    if (this.destinationCollections) {
      this.collection = this.destinationCollections.collection;
      return;
    }
  }

}
