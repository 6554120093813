import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'msgm-app-user-detail-message-filter',
  templateUrl: './user-detail-message-filter.component.html',
  styleUrls: ['./user-detail-message-filter.component.scss'],
})
export class UserDetailMessageFilterComponent implements OnInit {
  @Output() filterClick: EventEmitter<any> = new EventEmitter<any>();


  private range: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this._setUpForm();
  }

  ngOnInit() {
  }

  private _setUpForm() {
    this.range = this.formBuilder.group(
      {
        from: ['',
          [Validators.required]],
        to: ['',
          [Validators.required]],
      }
    );
  }

  onClickFilter() {
    const fromDate = this.range.get('from').value;
    const toDate = this.range.get('to').value;

    if (fromDate && toDate) {
      const filterValue  = {
        from: fromDate,
        to: toDate
      };
      this.filterClick.emit(filterValue);
    }
  }
}
