import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {
  DetailResultResponse1,
  MessageGetRequest1,
  MessageGetResponse1,
  UserGetDetail1,
  UserGetDetailDTO1,
  UserGetDTO1,
  UserGetRequest1,
  UserGetResponse1
} from 'msgm-api-lib';
import {concatMap} from 'rxjs/operators';
import {MessageGetDetail1, MessageGetDetailDTO1} from '../../../../../../msgm-api-lib/src/lib/msgm_angular-client';
import {UserDetailsMessageDetailsPopupComponent} from '../user-details-message-details-popup/user-details-message-details-popup.component';
import {UserDetailsService} from '../user-details.service';

@Component({
  selector: 'msgm-app-user-details-page',
  templateUrl: './user-details-page.component.html',
  styleUrls: ['./user-details-page.component.scss']
})
export class UserDetailsPageComponent implements OnInit {

  private _userGetDetailDTOs: UserGetDetailDTO1[];

  public iaSubject: string;

  constructor(private activatedRoute: ActivatedRoute, private userDetailsService: UserDetailsService, public dialog: MatDialog) {
    // First time, get subject from params
    this.activatedRoute.params.subscribe(params => {
      this.iaSubject = params['subject'] === 'undefined' ? this.iaSubject : params['subject'];
    });
  }

  ngOnInit() {
    this._readURLParamsAndFetchUserDetails();
  }

  private _readURLParamsAndFetchUserDetails() {

    this.activatedRoute.params.pipe(
      concatMap((params) => {

        const subject = params['subject'];
        const userGetRequest = this._getUserGetRequest(subject);
        return this.userDetailsService.getUserDetails(userGetRequest);

      })).subscribe((userGetResponse: UserGetResponse1) => {
      const userGetDetails: UserGetDetail1[] = userGetResponse.user_get_details;
      this._userGetDetailDTOs = this._getUserDetailDTOs(userGetDetails);
    });
  }

  private _getUserGetRequest(subject: string): UserGetRequest1 {
    const userGetDTO: UserGetDTO1 = {
      identity: {
        ia_subject: subject
      }
    };

    const userGetRequest: UserGetRequest1 = {
      user_get_dtos: [userGetDTO]
    };

    return userGetRequest;
  }

  private _getUserDetailDTOs(userGetDetails: UserGetDetail1[]): UserGetDetailDTO1[] {
    let successfullyProcessedUserDetailDTOs: UserGetDetailDTO1[] = [];

    for (let i = 0; i < userGetDetails.length; i++) {
      const userGetDetail: UserGetDetail1 = userGetDetails[i];
      const detailResultResponse: DetailResultResponse1 = userGetDetail.detail_result_response;

      if (detailResultResponse.result_state == 'WARNING') {
        console.warn('[UserDetails]: Warning while processing userGetDetail', detailResultResponse.result_message);
        continue;
      }

      if (detailResultResponse.result_state == 'ERROR') {
        console.warn('[UserDetails]: Error while processing userGetDetail', detailResultResponse.result_message);
        continue;
      }

      successfullyProcessedUserDetailDTOs.push(userGetDetail.user_create_detail_dto);
    }

    return successfullyProcessedUserDetailDTOs;
  }

  onMessageDetailsClick(element) {
    console.log('element', element);

    let messageGetRequest: MessageGetRequest1 = null;

    if (element.message) {
      messageGetRequest = {
        message_get_dtos: [{uid: element.message.uid}]
      };
    } else {
      messageGetRequest = {
        message_get_dtos: [{uid: element.uid}]
      };
    }

    this.userDetailsService.getMessage(messageGetRequest).subscribe((messageGetResponse: MessageGetResponse1) => {
      const messageDetailDTO: MessageGetDetailDTO1 = this._getMessageDetailDTO(messageGetResponse.message_get_details);
      const dialogRef = this.dialog.open(UserDetailsMessageDetailsPopupComponent, {data: messageDetailDTO.message});
    });

  }

  private _getMessageDetailDTO(messageGetDetails: MessageGetDetail1[]): MessageGetDetailDTO1 {

    let successfullyProcessedMessageGetDetailDTO: MessageGetDetailDTO1;

    for (let i = 0; i < messageGetDetails.length; i++) {
      const messageGetDetail: MessageGetDetail1 = messageGetDetails[i];
      const detailResultResponse: DetailResultResponse1 = messageGetDetail.detail_result_response;

      if (detailResultResponse.result_state === 'WARNING') {
        console.warn('[UserDetails]: Warning while processing messageGetDetail', detailResultResponse.result_message);
        continue;
      }

      if (detailResultResponse.result_state === 'ERROR') {
        console.error('[UserDetails]: Error while processing messageGetDetail', detailResultResponse.result_message);
        continue;
      }

      successfullyProcessedMessageGetDetailDTO = messageGetDetail.message_get_detail_dto;
    }

    return successfullyProcessedMessageGetDetailDTO;
  }

}
