import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DestinationCreateDTO1, DestinationCreateRequest1, IdentityDTO1} from 'msgm-api-lib';
import {DestinationDetailsService} from '../destination-details.service';
import DestTypeEnum = DestinationCreateDTO1.DestTypeEnum;
import JoinPolicyEnum = DestinationCreateDTO1.JoinPolicyEnum;
import {UserGetRequest} from '../../../../../../aua-api-lib/src/lib/aua_angular-client';
import {IdentityDTO} from 'aua-api-lib';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';

@Component({
  selector: 'msgm-app-destination-create-page',
  templateUrl: './destination-create-page.component.html',
  styleUrls: ['./destination-create-page.component.scss']
})
export class DestinationCreatePageComponent implements OnInit {

  private createDestinationForm: FormGroup;
  private ownerIdentity: IdentityDTO1;
  private userInfo: IdentityDTO;
  private subjectNotFoundMessage = 'Subject field is empty';
  private destType = DestTypeEnum.TOPIC;
  private joinPolicy = JoinPolicyEnum.PUBLIC;

  constructor(private formBuilder: FormBuilder, private router: Router, private destinationDetailsService: DestinationDetailsService, private snackbar: MatSnackBar) {
    this._setupForm();
  }

  ngOnInit(): void {
  }

  private _setupForm() {
    this.createDestinationForm = this.formBuilder.group(
      {
        title: ['', [Validators.required]],
        name: ['', [Validators.required]],
        subject: ['', [Validators.required]],
        domain: ['', [Validators.required]],
        alias: [''],
        join_policy: [],
        dest_type: [],
        receiver_interval: [1, [Validators.pattern('^[1-5]+$')]],
        sender_interval: [1, [Validators.pattern('^[1-5]+$')]],
      }
    );
  }

  onChangeSubjectField() {
    console.log('started');
    const iaSubject = this.createDestinationForm.get('subject').value;
    const regexp = new RegExp('[\\d\\w]*-([\\d\\w]*-)*[\\d\\w]*');
    const isMatched = regexp.test(iaSubject);
    if (isMatched) {
      const userGetRequest: UserGetRequest = {
        subject: iaSubject
      };

      this.destinationDetailsService.getIdentities(userGetRequest).subscribe((response) => {
        const userDetailResponse = response.user_get_identity_detail_response;
        if (userDetailResponse.detail_result_response.result_state === 'OK') {
            this.ownerIdentity = {
              ia_subject: iaSubject
            };

            this.userInfo = userDetailResponse.identities[0];
        } else {
          this.ownerIdentity = undefined;
          this.userInfo = undefined;
          this.subjectNotFoundMessage = 'No user found for the subject. \n' + iaSubject;
        }
      });
    } else {
        this.ownerIdentity = undefined;
        this.userInfo = undefined;
        this.subjectNotFoundMessage = 'Subject is not valid';
    }
  }

  onChangeDestType(event: any) {
      this.destType = event.value;
  }

  onChangeJoinPolicy(event: any) {
      this.joinPolicy = event.value;
  }

  onCreateDestinationSubmit(form: FormGroup) {
    const destinationCreateDTOS: DestinationCreateDTO1[] = [];

    const destinationCreateDto: DestinationCreateDTO1 = {
      title: form.get('title').value,
      name: form.get('name').value,
      owner_identity: this.ownerIdentity,
      domain: form.get('domain').value,
      alias: form.get('alias').value,
      join_policy: this.joinPolicy,
      dest_type: this.destType,
      receive_interval: form.get('receiver_interval').value,
      send_interval: form.get('sender_interval').value

    };
    destinationCreateDTOS.push(destinationCreateDto);

    const destinationCreateRequest: DestinationCreateRequest1 = {
      destination_create_dtos: destinationCreateDTOS
    };

    this.destinationDetailsService.createDestination(destinationCreateRequest).subscribe((response) => {
      if (response.global_response.result_state === 'OK') {
        this.router.navigate(['destination/extended-search']);
        this.snackbar.open('Destination was successfully created.', '', {
          duration: 5000,
        });
      } else {
        this.snackbar.open('Destination was not created.', '', {
          duration: 5000,
        });
      }

    });
  }

}
