import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'msgm-app-breadcrumb-layout',
  templateUrl: './breadcrumb-layout.component.html',
  styleUrls: ['./breadcrumb-layout.component.scss']
})
export class BreadcrumbLayoutComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  }

  revealDisplayName(displayName: string) {
    if (displayName.includes('{uid}')) {
      const splitedValue = this.router.url.split('/');
      const value = splitedValue[splitedValue.length - 1];
      displayName = displayName.replace('{uid}', value);
    }

    return displayName;
  }

}
