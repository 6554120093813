import {Injectable, Provider} from '@angular/core';

export class MsgmLibConfiguration {
    apiConfig: {
        basePathMsgm?: string,
        authorizationSessionStorageKey?: string
    };
}

@Injectable({providedIn: 'root'})
export abstract class MsgmConfigurationProvider {
    abstract get config(): MsgmLibConfiguration
}

@Injectable({providedIn: 'root'})
export class DefaultMsgmConfiguration extends MsgmConfigurationProvider {
    get config(): MsgmLibConfiguration {
        return {
            apiConfig: {
                basePathMsgm: 'https://igate.beook.work/msgm/v1',
                authorizationSessionStorageKey: 'authorization'
            }
        };
    }
}

export class MsgmConfiguration {
    config?: Provider;
}
