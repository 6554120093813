import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageDeliveryCollectionDTO1, MessageDeliveryCollectionDTO1Collection} from 'msgm-api-lib';

@Component({
  selector: 'msgm-app-user-detail-message-incomming-table',
  templateUrl: './user-detail-message-incomming-table.component.html',
  styleUrls: ['./user-detail-message-incomming-table.component.css']
})
export class UserDetailMessageIncommingTableComponent implements OnInit {
  @Input() messageDeliveryCollectionDTO: MessageDeliveryCollectionDTO1;
  @Output() rowClick = new EventEmitter();

  public displayedColumns: string[] = ['created_at', 'attempts', 'consumed_at', 'sender'];

  public count: number = 0;

  public collection: MessageDeliveryCollectionDTO1Collection[];
  public selectedRowInfo: MessageDeliveryCollectionDTO1Collection;

  constructor() {}

  ngOnInit() {
    this._initCollection();
  }

  private _initCollection() {
    if (typeof this.messageDeliveryCollectionDTO !== 'undefined') {
      this.collection = this.messageDeliveryCollectionDTO.collection;
      this.count = this.messageDeliveryCollectionDTO.total_count;
    }
  }

  public onRowClick(row: any) {
    this.selectedRowInfo = row;
    this.rowClick.emit(row);
  }

  /**
   * @param dto
   */
  public getCreatedAt(dto: MessageDeliveryCollectionDTO1Collection): Date {
    return dto.message.time_stats.created_at;
  }

  /**
   * @param dto
   */
  public getDeliveryAttempts(dto: MessageDeliveryCollectionDTO1Collection): number {
    return dto.delivery.delivery_attempts;
  }

  /**
   * @param dto
   */
  public getConsumedAt(dto: MessageDeliveryCollectionDTO1Collection): Date {
    return dto.delivery.consumed_at;
  }

  /**
   * @param dto
   */
  public getSenderIdentity(dto: MessageDeliveryCollectionDTO1Collection): string {
    return dto.message.dto.sender_identity.ia_subject;
  }


}
