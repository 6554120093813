import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SiteLayoutComponent} from './component/_layout/site-layout/site-layout.component';
import {DestinationDetailsPageComponent} from './component/destination-details/destination-details-page/destination-details-page.component';
import {ExtendedSearchPageComponent} from './component/extended-search/extended-search-page/extended-search-page.component';
import {MsgmDashboardLoginComponent} from './component/msgm-dashboard-login/msgm-dashboard-login.component';
import {MsgmDetailsPageComponent} from './component/msgm-details/msgm-details-page/msgm-details-page.component';
import {MsgmDetailsSearchPageComponent} from './component/msgm-details/msgm-details-search-page/msgm-details-search-page.component';
import {OauthCallbackComponent} from './component/oauth-callback/oauth-callback.component';
import {UserDetailIncommingDeliveriesComponent} from './component/user-details/user-detail-incomming-deliveries/user-detail-incomming-deliveries.component';
import {UserDetailOutgoingMessagesComponent} from './component/user-details/user-detail-outgoing-messages/user-detail-outgoing-messages.component';
import {UserDetailsPageComponent} from './component/user-details/user-details-page/user-details-page.component';
import {DestinationCreatePageComponent} from './component/destination-details/destination-create-page/destination-create-page.component';
import {DestinationPageComponent} from './component/destination-details/destination-page/destination-page.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: MsgmDashboardLoginComponent,
  },
  {
    path: 'auth-callback',
    component: OauthCallbackComponent,
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: 'destination',
        component: DestinationPageComponent,
        data: {breadcrumb: 'Destination'},
        children: [
          {
            path: 'extended-search',
            component: ExtendedSearchPageComponent,
            data: {breadcrumb: 'Search'},
          },
          {
            path: 'destination-details/:uid',
            component: DestinationDetailsPageComponent,
            data: {breadcrumb: 'Detail - {uid}'}
          },
          {
            path: 'create',
            component: DestinationCreatePageComponent,
            data: {breadcrumb: 'New'}
          }
          ]
      },
      {
        path: 'user-details',
        component: UserDetailsPageComponent,
        data: {breadcrumb: 'User Detail'},
        children: [
          {
            path: 'incomming-deliveries/:subject',
            component: UserDetailIncommingDeliveriesComponent,
            data: {breadcrumb: 'Incoming - {uid}'},
          },
          {
            path: 'outgoing-messages/:subject',
            component: UserDetailOutgoingMessagesComponent,
            data: {breadcrumb: 'Outgoing - {uid}'},
          }
        ]
      },
      {
        path: 'message-details-search',
        component: MsgmDetailsSearchPageComponent,
        data: {breadcrumb: 'Message'}
      },
      {
        path: 'message-details/:uid',
        component: MsgmDetailsPageComponent,
        data: {breadcrumb: 'Message-Detail / {uid}'}
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
