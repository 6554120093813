import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'msgm-app-user-info-popup',
  templateUrl: './user-info-popup.component.html',
  styleUrls: ['./user-info-popup.component.scss']
})
export class UserInfoPopupComponent implements OnInit {
  private identities: any;
  constructor(
    public dialogRef: MatDialogRef<UserInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('User info Popup');
    console.log(this.data);
    this.identities = this.data.identities[0];
  }

}
