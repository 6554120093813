/**
 * ioneaccess Message Broker Management (MSGM) API
 * API for ioneaccess Message Broker Management (MSGM)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Response object which contains the state of the member for the destination.
 */
export interface DestinationJoinDetailDTO1 { 
    /**
     * DTO identifier to correlate.
     */
    dto_id?: string;
    /**
     * Whether the member's state is pending (join sent - not yet accepted), allowed or banned
     */
    state: DestinationJoinDetailDTO1.StateEnum;
    /**
     * Defines what a member can do.   READ = the member can only read messages   WRITE = the member can only write messages   READWRITE = the member can read and write messages This property is ignored when the destination is of type 'QUEUE'. 
     */
    permissions: DestinationJoinDetailDTO1.PermissionsEnum;
}
export namespace DestinationJoinDetailDTO1 {
    export type StateEnum = 'PENDING' | 'ALLOWED' | 'BANNED';
    export const StateEnum = {
        PENDING: 'PENDING' as StateEnum,
        ALLOWED: 'ALLOWED' as StateEnum,
        BANNED: 'BANNED' as StateEnum
    };
    export type PermissionsEnum = 'READ' | 'WRITE' | 'READWRITE';
    export const PermissionsEnum = {
        READ: 'READ' as PermissionsEnum,
        WRITE: 'WRITE' as PermissionsEnum,
        READWRITE: 'READWRITE' as PermissionsEnum
    };
}
